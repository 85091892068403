<template>
	<Dialog
		modal
		:header="`Add ${props.moduleSchema.displayName} Relation`"
		class="new-relation-form"
		@update:visible="reset(), emit('update:visible', $event)">
		<form
			class="d-flex"
			@submit.prevent="handleCreate">
			<div class="new-relation-form__form-content-container">
				<div class="new-relation-form__background-type-line" />
				<div class="new-relation-form__module-container">
					<div class="new-relation-form__module-header">
						<i
							class="new-relation-form__module-icon"
							:class="props.moduleSchema.icon"></i>
						<div class="new-relation-form__module-name">{{ props.moduleSchema.displayName }}</div>
					</div>
					<div class="form__input-container">
						<label :for="`${uuid}-name`">Relation Name</label>
						<InputText
							v-if="!props.hideInputs.includes('name')"
							:id="`${uuid}-name`"
							ref="nameInput"
							v-model="name"
							:disabled="!!props.forceValues.name"
							pattern="[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?"
							title="Unique relation name in camelCase"
							:placeholder="relationNamePlaceholder(false)"
							@input="clearValidationErrors()"
							@blur="name = anyToCamelCase(name)"
							@keydown.enter="name = anyToCamelCase(name)" />
					</div>
				</div>
				<div class="new-relation-form__relation-type-container">
					<!--<Button
						class="new-relation-form__relation-type-button"
						:severity="selectedType === 'HasOne' ? 'primary' : 'secondary'"
						@click="setRelationType('HasOne')">
						<HO
							class="new-relation-form__relation-type-icon"
							:class="{
								'new-relation-form__relation-type-icon--selected': selectedType === 'HasOne',
							}" />
					</Button>-->
					<Button
						class="new-relation-form__relation-type-button"
						:severity="selectedType === 'HasAndBelongsToOne' ? 'primary' : 'secondary'"
						@click="setRelationType('HasAndBelongsToOne')">
						<HBO
							class="new-relation-form__relation-type-icon"
							:class="{
								'new-relation-form__relation-type-icon--selected':
									selectedType === 'HasAndBelongsToOne',
							}" />
					</Button>
					<Button
						class="new-relation-form__relation-type-button"
						:severity="selectedType === 'HasAndBelongsToMany' ? 'primary' : 'secondary'"
						@click="setRelationType('HasAndBelongsToMany')">
						<HBM
							class="new-relation-form__relation-type-icon"
							:class="{
								'new-relation-form__relation-type-icon--selected':
									selectedType === 'HasAndBelongsToMany',
							}" />
					</Button>
					<!--<Button
					label="BMHM"
					@click="setRelationType('BelongsToAndHasMany')"></Button>-->
					<!--<Button
						class="new-relation-form__relation-type-button"
						:severity="selectedType === 'HasMany' ? 'primary' : 'secondary'"
						@click="setRelationType('HasMany')">
						<HM
							class="new-relation-form__relation-type-icon"
							:class="{
								'new-relation-form__relation-type-icon--selected': selectedType === 'HasMany',
							}" />
					</Button>-->
					<Button
						class="new-relation-form__relation-type-button"
						:severity="selectedType === 'BelongsToMany' ? 'primary' : 'secondary'"
						@click="setRelationType('BelongsToMany')">
						<HMHM
							class="new-relation-form__relation-type-icon"
							:class="{
								'new-relation-form__relation-type-icon--selected': selectedType === 'BelongsToMany',
							}" />
					</Button>
				</div>
				<div class="new-relation-form__module-container">
					<div
						class="new-relation-form__module-header new-relation-form__module-header--no-padding">
						<Select
							v-if="!props.hideInputs.includes('relatedModule')"
							:id="`${uuid}-related-module`"
							v-model="relatedModule"
							class="new-relation-form__module-select"
							:options="modules"
							:disabled="!!props.forceValues.relatedModule"
							option-label="title"
							option-value="value"
							@input="clearValidationErrors()"
							@change="resetNames()">
							<template #value>
								<div class="new-relation-form__module-select-value">
									<i
										class="new-relation-form__module-select-value-icon"
										:class="`${relatedModuleSchema.icon}`"></i>
									<div
										class="new-relation-form__module-select-value-name"
										style="margin-left: 10px">
										{{ relatedModuleSchema.displayName }}
									</div>
								</div>
							</template>
							<template #option="slotProps">
								<i
									:class="
										allModuleSchemas.find((m) => m.name === slotProps.option.value)!.icon
									"></i>
								<div style="margin-left: 10px">
									{{ allModuleSchemas.find((m) => m.name === slotProps.option.value)!.displayName }}
								</div>
							</template>
						</Select>
					</div>
					<div class="form__input-container">
						<label :for="`${uuid}-inverse-name`">Inverse Relation Name</label>
						<InputText
							v-if="!props.hideInputs.includes('inverseName')"
							:id="`${uuid}-inverse-name`"
							ref="inverseNameInput"
							v-model="inverseName"
							:disabled="!inverse"
							pattern="[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?"
							title="Unique relation name in camelCase"
							:placeholder="relationNamePlaceholder(true)"
							@input="clearValidationErrors()"
							@blur="inverseName = anyToCamelCase(inverseName)"
							@keydown.enter="inverseName = anyToCamelCase(inverseName)" />
					</div>
				</div>
			</div>
			<RelationDiagram
				class="new-relation-form__relation-diagram"
				:schema="compiledRelation"
				:inverse-module-schema="relatedModuleSchema"
				:inverse-relation-schema="compiledInverseRelation"
				:module-schema="moduleSchema" />
			<Button
				icon="fal fa-save"
				label="Create"
				type="submit"
				@submit.prevent="handleCreate" />
		</form>
	</Dialog>
</template>

<script setup lang="ts">
import { anyToCamelCase, pascalToCamelCase, anyToLabelCase } from '@/helpers/strings'
import type {
	EnrichedModuleSchema,
	EnrichedRelationSchema,
} from '@/interfaces/schemas/enrichedModuleSchema'
import type { availableRelationTypes } from '@/interfaces/schemas/moduleSchema'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Select from 'primevue/select'
import Button from 'primevue/button'
import { computed, getCurrentInstance, ref, watch } from 'vue'
import RelationDiagram from './RelationDiagram.vue'
import HBM from '../assets/HBM.vue'
import HBO from '../assets/HBO.vue'
import HMHM from '../assets/HMHM.vue'
import type { ComponentPublicInstance } from 'vue'

const emit = defineEmits<{
	(
		e: 'create',
		payload: { relation: EnrichedRelationSchema; inverseRelation: EnrichedRelationSchema | null },
	): void
	(e: 'update:visible', value: boolean): void
}>()

const props = withDefaults(
	defineProps<{
		hideInputs?: string[]
		forceValues?: Record<string, any>
		moduleSchema: EnrichedModuleSchema
		allModuleSchemas: EnrichedModuleSchema[]
	}>(),
	{
		hideInputs: () => [],
		forceValues: () => ({}),
	},
)

const instance = getCurrentInstance()
const uuid = ref(instance!.uid)
const name = ref('')
const type = ref<(typeof availableRelationTypes)[number]>('HasMany')
const relatedModule = ref('')
const inverse = ref(true)
const inverseName = ref(`${props.moduleSchema.name}s`)
const nameInput = ref<ComponentPublicInstance<typeof InputText> | null>(null)
const inverseNameInput = ref<ComponentPublicInstance<typeof InputText> | null>(null)
const selectedType = ref('HasAndBelongsToMany')

const modules = computed(() => {
	return props.allModuleSchemas.map((module) => ({
		title: module.displayName,
		value: module.name,
	}))
})
const relatedModuleSchema = computed(() => {
	return props.allModuleSchemas.find((m) => m.name === relatedModule.value)!
})
const nameDoesntConflictWithRelations = computed(() => {
	return !props.moduleSchema.model.relations[name.value]
})
const inverseNameDoesntConflictWithRelations = computed(() => {
	if (!relatedModuleSchema.value) return false
	return !relatedModuleSchema.value.model.relations[inverseName.value]
})
const nameDoesntConflictWithColumns = computed(() => {
	return !props.moduleSchema.model.columns[name.value]
})
const inverseNameDoesntConflictWithColumns = computed(() => {
	if (!relatedModuleSchema.value) return false
	return !relatedModuleSchema.value.model.columns[inverseName.value]
})
const compiledRelation = computed(() => {
	return {
		name: name.value,
		displayName: anyToLabelCase(name.value),
		relationType: type.value,
		model: relatedModule.value,
		inverse: inverseName.value,
	} satisfies EnrichedRelationSchema
})
const compiledInverseRelation = computed(() => {
	if (!inverse.value) return null
	let inverseRelation: EnrichedRelationSchema = {
		name: inverseName.value,
		displayName: anyToLabelCase(inverseName.value),
		relationType: 'BelongsTo',
		model: props.moduleSchema.name,
		inverse: name.value,
	}
	if (compiledRelation.value.relationType === 'BelongsToMany') {
		inverseRelation.relationType = 'BelongsToMany'
	}
	return inverseRelation
})

watch(
	modules,
	() => {
		reset()
	},
	{ immediate: true },
)
watch(type, resetNames)

function relationNamePlaceholder(inverse: boolean) {
	if (inverse) {
		if (type.value === 'BelongsToMany') {
			return `relationName (plural)`
		} else {
			return `relationName (singular)`
		}
	} else {
		if (type.value === 'BelongsToMany' || type.value === 'HasMany') {
			return `relationName (singular)`
		} else {
			return `relationName (plural)`
		}
	}
}

function validateInputs() {
	let valid = true
	if (
		nameInput.value &&
		relatedModule.value === props.moduleSchema.name &&
		name.value === inverseName.value
	) {
		nameInput.value.$el.setCustomValidity(
			'Relation and inverse relation must have different names when defined on the same module',
		)
		nameInput.value.$el.reportValidity()
		valid = false
	}
	if (nameInput.value && !nameDoesntConflictWithRelations.value) {
		nameInput.value.$el.setCustomValidity(
			`Relation with same name already exists on ${props.moduleSchema.name} module`,
		)
		nameInput.value.$el.reportValidity()
		valid = false
	}
	if (inverse.value && inverseNameInput.value && !inverseNameDoesntConflictWithRelations.value) {
		inverseNameInput.value.$el.setCustomValidity(
			`Inverse relation name on inverse ${relatedModuleSchema.value!.name} module already exists`,
		)
		inverseNameInput.value.$el.reportValidity()
		valid = false
	}
	if (nameInput.value && !nameDoesntConflictWithColumns.value) {
		nameInput.value.$el.setCustomValidity(
			`Column with same name name already exists on ${props.moduleSchema.name} module`,
		)
		nameInput.value.$el.reportValidity()
		valid = false
	}
	if (inverse.value && inverseNameInput.value && !inverseNameDoesntConflictWithColumns.value) {
		inverseNameInput.value.$el.setCustomValidity(
			`Column with same name on inverse ${relatedModuleSchema.value!.name} module already exists`,
		)
		inverseNameInput.value.$el.reportValidity()
		valid = false
	}
	return valid
}

function handleCreate() {
	if (!validateInputs()) return

	emit('create', {
		relation: compiledRelation.value,
		inverseRelation: compiledInverseRelation.value,
	})
	reset()
}

function reset() {
	type.value = 'HasMany'
	relatedModule.value = modules.value[0].value
	inverse.value = true
	selectedType.value = 'HasAndBelongsToMany'
	resetNames()
}

function resetNames() {
	if (type.value === 'BelongsToMany' || type.value === 'HasMany') {
		name.value = pascalToCamelCase(relatedModuleSchema.value!.model.namePlural)
	} else {
		name.value = pascalToCamelCase(relatedModuleSchema.value!.model.name)
	}

	if (type.value === 'BelongsToMany') {
		inverseName.value = pascalToCamelCase(props.moduleSchema.model.namePlural)
	} else {
		inverseName.value = pascalToCamelCase(props.moduleSchema.model.name)
	}
}

function clearValidationErrors() {
	if (nameInput.value) {
		nameInput.value.$el.setCustomValidity('')
	}
	if (inverseNameInput.value) {
		inverseNameInput.value.$el.setCustomValidity('')
	}
}

function setRelationType(
	newType:
		| 'HasOne'
		| 'HasAndBelongsToOne'
		| 'HasAndBelongsToMany'
		//| 'BelongsToAndHasMany'
		| 'BelongsToMany'
		| 'HasMany',
) {
	selectedType.value = newType
	if (newType === 'HasOne') {
		type.value = 'HasOne'
		inverse.value = false
	} else if (newType === 'HasAndBelongsToOne') {
		type.value = 'HasOne'
		inverse.value = true
	} else if (newType === 'HasAndBelongsToMany') {
		type.value = 'HasMany'
		inverse.value = true
	} else if (newType === 'BelongsToMany') {
		type.value = 'BelongsToMany'
		inverse.value = true
	} else if (newType === 'HasMany') {
		type.value = 'HasMany'
		inverse.value = false
	}
	resetNames()
}
</script>

<style lang="scss" scoped>
.new-relation-form {
	form {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 900px;

		& > * {
			width: 100%;
		}

		.form__input-container {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin-bottom: 10px;
		}

		.new-relation-form__form-content-container {
			display: flex;
			gap: 10px;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			height: 175px;
			position: relative;

			.new-relation-form__background-type-line {
				background: var(--p-primary-500);
				height: 2px;
				width: 100%;
				position: absolute;
				top: calc(50% - 1px);
				z-index: -1;
			}

			.new-relation-form__module-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 250px;
				background: var(--p-surface-100);
				padding: 20px;
				border-radius: 10px;

				@media (prefers-color-scheme: dark) {
					background: var(--p-surface-950);
				}

				.new-relation-form__module-header {
					display: flex;
					align-items: center;
					gap: 5px;
					background: var(--p-surface-0);
					border-radius: 5px;
					padding: 8px 12px;

					@media (prefers-color-scheme: dark) {
						border: 1px solid var(--p-inputtext-border-color);
						background: var(--p-surface-950);
					}

					&--no-padding {
						padding: 0;
					}

					.new-relation-form__module-icon {
						font-size: 24px;
						color: var(--p-primary-500);
					}

					.new-relation-form__module-name {
						font-size: 18px;
						color: var(--p-primary-500);
					}

					.new-relation-form__module-select {
						width: 100%;

						.new-relation-form__module-select-value {
							display: flex;
							align-items: center;

							.new-relation-form__module-select-value-icon {
								font-size: 24px;
								color: var(--p-primary-500);
							}

							.new-relation-form__module-select-value-name {
								font-size: 18px;
								color: var(--p-primary-500);
							}
						}
					}
				}
			}

			.new-relation-form__relation-type-container {
				display: flex;
				gap: 10px;

				.new-relation-form__relation-type-button {
					padding: 8px;

					.new-relation-form__relation-type-icon {
						width: 30px;
						height: 30px;

						:deep(circle) {
							stroke: var(--p-primary-500);
						}

						:deep(rect),
						:deep(path) {
							fill: var(--p-primary-500);
						}

						&--selected {
							:deep(circle) {
								stroke: var(--p-surface-0);

								@media (prefers-color-scheme: dark) {
									stroke: var(--p-surface-950);
								}
							}

							:deep(rect),
							:deep(path) {
								fill: var(--p-surface-0);

								@media (prefers-color-scheme: dark) {
									fill: var(--p-surface-950);
								}
							}
						}
					}
				}
			}
		}

		.new-relation-form__relation-diagram {
			border: 1px solid var(--p-surface-200);
			border-radius: 10px;

			@media (prefers-color-scheme: dark) {
				border: none;
			}
		}
	}
}
</style>
