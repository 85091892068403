<template>
	<Header
		:list-state="listState"
		search
		title="Deployments" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="status"
				header="Status" />
			<Column
				field="started_at"
				header="Started At" />
			<Column
				field="finished_at"
				header="Finished At" />
			<Column
				field="droplet_id"
				header="Droplet Id" />
			<Column header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Container from '@/components/Container.vue'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'
import { useDeploymentListState } from '@/models/Deployment/States'
import type { Deployment } from '@/models/Deployment/Model'

const listState = useDeploymentListState()
const router = useRouter()

onBeforeMount(() => {
	listState.getList()
})

function openDetails(item: { data: Deployment }) {
	router.push({ name: 'deployments-edit', params: { id: item.data.id } })
}
</script>
