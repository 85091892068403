import type { AppModel } from '@/models/App/Model'
import AppsApi from '@/models/App/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class AppDetailsState extends DetailsState<AppsApi, AppModel> {
	api = new AppsApi()
}

export function useAppDetailsState() {
	return new AppDetailsState()
}

export class AppListState extends ListState<
	AppsApi,
	AppModel,
	LaravelPaginationResponse<AppModel>
> {
	api = new AppsApi()
}

export function useAppListState() {
	return new AppListState()
}
