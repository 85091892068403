import Api from '@/helpers/models/Api'
import type { BuildModel, BuildStorePayload, BuildUpdatePayload } from './Model'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class BuildsApi extends Api<
	BuildModel,
	LaravelPaginationResponse<BuildModel>,
	BuildStorePayload,
	BuildUpdatePayload
> {
	route = 'builds'
}
