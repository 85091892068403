import axios, { type AxiosResponse } from 'axios'

export type Icon = {
	id: string
	label: string
	search: string
	familyStylesByLicense: { pro: { prefix: string }[] }
}

export interface FontAwesomeSearchRepsonse {
	data: {
		search: Icon[]
	}
}

export default class FontAwesomeApi {
	requestController = new AbortController()

	async search(
		searchString: string,
	): Promise<AxiosResponse<FontAwesomeSearchRepsonse> | undefined> {
		if (!this.requestController.signal.aborted) {
			this.requestController.abort()
			this.requestController = new AbortController()
		}
		const query = `query { search(version: "6.0.0", query: "${searchString}", first: 50) { id, label, familyStylesByLicense { pro { prefix } } } }`
		try {
			const res = await axios.post(
				'https://api.fontawesome.com',
				{
					query,
				},
				{
					withCredentials: false,
					withXSRFToken: false,
					signal: this.requestController.signal,
				},
			)
			return res
		} catch (e: any) {
			if (e.name === 'CanceledError') {
				return undefined
			}
			throw e
		}
	}
}
