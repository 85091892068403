<template>
	<div>
		<div style="position: relative">
			<PrimeButton
				ref="button"
				class="confettiButton"
				:label="buttonText"
				icon="fal fa-circle-check"
				@click="explode" />
			<canvas
				ref="canvas"
				class="confetti-canvas"></canvas>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import PrimeButton from 'primevue/button'

defineProps({
	buttonText: {
		type: String,
		default: 'Click me for confetti!',
	},
})

const canvas = ref<HTMLCanvasElement | null>(null)
const button = ref<ComponentPublicInstance<typeof PrimeButton> | null>(null)
const confettiPieces: any[] = []

let ctx: CanvasRenderingContext2D | null = null
let canvasWidth = 0
let canvasHeight = 0

function resizeCanvas() {
	if (!canvas.value) return
	canvas.value.width = window.innerWidth
	canvasWidth = canvas.value.width
	canvas.value.height = window.innerHeight
	canvasHeight = canvas.value.height
}

function render() {
	if (!ctx) return
	ctx.clearRect(0, 0, canvasWidth, canvasHeight)

	confettiPieces.forEach((piece: any, index: number) => {
		piece.y += piece.gravity
		piece.x += piece.wind
		piece.rotation += piece.rotationSpeed
		if (piece.y > canvasHeight) {
			confettiPieces.splice(index, 1)
		} else {
			drawConfetti(piece)
		}
	})
	requestAnimationFrame(render)
}

function drawConfetti(piece: any) {
	if (!ctx) return
	ctx.save()
	ctx.translate(piece.x, piece.y)
	ctx.rotate(piece.rotation)
	ctx.fillStyle = piece.color
	ctx.fillRect(-piece.size / 2, -piece.size / 2, piece.size, piece.size)
	ctx.restore()
}

function createConfettiPiece() {
	if (!canvas.value) return null
	return {
		x: Math.random() * canvasWidth,
		y: -100,
		size: Math.random() * 10 + 5,
		color: `hsl(${Math.random() * 360}, 70%, 60%)`,
		gravity: Math.random() * 12 + 1,
		wind: Math.random() * 6 * (Math.random() >= 0.5 ? 1 : -1),
		rotation: Math.random() * 2 * Math.PI,
		rotationSpeed: Math.random() * 0.1 - 0.05,
	}
}

const explode = () => {
	for (let i = 0; i < 100; i++) {
		const piece = createConfettiPiece()
		if (piece) {
			confettiPieces.push(piece)
		}
	}
}

onMounted(() => {
	if (!canvas.value) return
	ctx = canvas.value.getContext('2d')
	if (!ctx) return
	resizeCanvas()
	window.addEventListener('resize', resizeCanvas)
	requestAnimationFrame(render)
})

onBeforeUnmount(() => {
	window.removeEventListener('resize', resizeCanvas)
})
</script>

<style>
.confetti-canvas {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 9999;
}

.confettiButton {
	width: 100%;
}
</style>
