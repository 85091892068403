<template>
	<Card class="app">
		<template #title>
			<h4 class="app__title">App</h4>
			<Button
				v-if="detailsState.details.value?.app"
				icon="fal fa-link"
				label="Reconnect"
				outlined
				severity="secondary"
				@click="isRelationAddDialogActive = true" />
			<Button
				icon="fal fa-plus"
				label="Create"
				outlined
				severity="secondary"
				@click="isFormActive = true" />
		</template>
		<template #content>
			<div class="app__content-container">
				<Button
					v-if="!detailsState.details.value?.app"
					icon="fal fa-link"
					severity="secondary"
					outlined
					class="app__add-button"
					label="Click to add User"
					@click="isRelationAddDialogActive = true"></Button>
				<Card
					v-else
					icon="fal fa-link"
					class="app__entity-card"
					@click="openDetails">
					<template #content>
						<p>{{ detailsState.details.value!.app!.name }}</p>
						<Button
							class="app__dissociate-button"
							severity="secondary"
							text
							rounded
							icon="fal fa-times"
							@click.stop="dissociate" />
					</template>
				</Card>
			</div>
		</template>
	</Card>
	<AppRelationAddDialog
		v-model="isRelationAddDialogActive"
		:repository-config-id="props.repositoryConfigId"
		@update="refresh()" />
	<AppForm
		:as-dialog="true"
		:visible="isFormActive"
		:should-redirect="false"
		:attach-to="{ repositoryConfig: { method: 'associate', id: repositoryConfigId } }"
		@close="isFormActive = false"
		@created="refresh()" />
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import AppRelationAddDialog from './AppRelationAddDialog.vue'
import AppForm from '@/views/App/components/Form.vue'
import Card from 'primevue/card'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { useRepositoryConfigDetailsState } from '@/models/RepositoryConfig/States'
import type { RepositoryConfig } from '@/models/RepositoryConfig/Model'
import RepositoryConfigsApi from '@/models/RepositoryConfig/Api'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	repositoryConfigId: RepositoryConfig['id']
}>()

const router = useRouter()
const isRelationAddDialogActive = ref(false)
const isFormActive = ref(false)
const dissociateLoading = ref(false)
const listLoading = ref(false)
const detailsState = useRepositoryConfigDetailsState()

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await detailsState.getDetails(props.repositoryConfigId, { with: ['app'] })
	listLoading.value = false
	emit('stop-loading')
}

async function dissociate() {
	if (!detailsState.details.value) return
	dissociateLoading.value = true
	emit('start-loading')
	await new RepositoryConfigsApi().updateRelation(props.repositoryConfigId, 'app', {
		method: 'dissociate',
		params: [detailsState.details.value.id],
	})
	dissociateLoading.value = false
	await refresh()
	emit('stop-loading')
}

function openDetails() {
	if (!detailsState.details.value) return
	router.push({ name: 'apps-details', params: { id: detailsState.details.value.id } })
}
</script>

<style lang="scss" scoped>
.app {
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.app__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}

	.app__content-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 0 10px 10px;

		.app__add-button {
			width: 100%;
		}

		.app__entity-card {
			width: 100%;

			@media (prefers-color-scheme: dark) {
				background: var(--p-surface-800);
			}

			&:hover {
				cursor: pointer;
			}

			:deep(.p-card-body) {
				padding: 0;

				.p-card-content {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					padding: 10px 10px 10px 20px;

					.app__dissociate-button:hover {
						@media (prefers-color-scheme: dark) {
							background: var(--p-surface-700);
						}
					}
				}
			}
		}
	}
}
</style>
