<template>
	<Card class="relation">
		<template #content>
			<div class="flex p-5 items-center gap-4">
				<div class="relation__type-container">
					<i :class="'relation__icon fal ' + iconMap[props.schema.relationType]"></i>
				</div>
				<div class="relation__name-container">
					<div class="relation__name">{{ props.schema.displayName }}</div>
					<div class="relation__description-container">
						<div class="relation__model">{{ props.moduleSchema.displayName }}</div>
						<a
							class="relation__relation-type"
							target="_blank"
							:href="relationTypeDocs[props.schema.relationType]">
							{{ relationTypeMap[props.schema.relationType] }}
						</a>
						<span class="relation__inverse-container">
							<i :class="'relation__icon ' + props.inverseModuleSchema.icon"></i>
							<div class="relation__inverse">{{ props.inverseModuleSchema.displayName }}</div>
						</span>
					</div>
				</div>
				<Button
					text
					rounded
					icon="fal fa-trash"
					severity="secondary"
					@click="emit('delete')" />
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
import type {
	EnrichedRelationSchema,
	EnrichedModuleSchema,
} from '@/interfaces/schemas/enrichedModuleSchema'
import Card from 'primevue/card'
import Button from 'primevue/button'

const iconMap = {
	BelongsTo: 'fa-arrow-left-long',
	HasMany: 'fa-split',
	BelongsToMany: 'fa-circle-nodes',
	HasOne: 'fa-arrow-right-long',
}

const relationTypeMap = {
	BelongsTo: 'belongs to',
	HasMany: 'has many',
	BelongsToMany: 'belongs to many',
	HasOne: 'has one',
}

const relationTypeDocs = {
	BelongsTo: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-many-inverse',
	HasMany: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-many',
	BelongsToMany: 'https://laravel.com/docs/11.x/eloquent-relationships#many-to-many',
	HasOne: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-one',
}

const emit = defineEmits<{
	(e: 'delete'): void
}>()

const props = defineProps<{
	schema: EnrichedRelationSchema
	inverseModuleSchema: EnrichedModuleSchema
	moduleSchema: EnrichedModuleSchema
	readonly: boolean
}>()
</script>

<style scoped lang="scss">
.relation {
	overflow: hidden;
	margin-bottom: 12px;

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-800);
	}

	&:deep(.p-card-body) {
		padding: 0;

		.relation__type-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;

			.relation__icon {
				font-size: 34px;
				color: var(--p-emerald-500);
			}

			.relation__type,
			.relation__model {
				font-size: 12px;
				flex: 1;
				text-align: center;
			}

			.relation__inverse {
				font-size: 10px;
				flex: 1;
			}
		}

		.relation__name {
			font-size: 16px;
			flex: 1;
		}
	}

	.relation__name-container {
		display: flex;
		flex-direction: column;
		flex: 1;

		.relation__description-container {
			display: flex;
			flex: 1;
			align-items: center;
			gap: 6px;

			.relation__relation-type {
				font-size: 12px;
				color: var(--p-primary-500);
			}

			.relation__model,
			.relation__inverse {
				font-size: 12px;
			}

			.relation__inverse-container {
				display: flex;
				align-items: center;
				gap: 4px;

				.relation__icon {
					font-size: 10px;
				}
			}
		}
	}
}
</style>
