import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'
import type {
	CodeDeliveryModel,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload,
} from './Model'
import Api from '@/helpers/models/Api'

export default class CodeDeliveriesApi extends Api<
	CodeDeliveryModel,
	LaravelPaginationResponse<CodeDeliveryModel>,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload
> {
	route = 'code_deliveries'
}
