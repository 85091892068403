<template>
	<div class="modules-sidebar">
		<div class="modules-sidebar__title">Modules</div>
		<template
			v-for="module in modules"
			:key="module.name">
			<Draggable
				:ref="
					(component: unknown) =>
						setModuleRef(module.name, component as ComponentPublicInstance | null)
				"
				class="column__draggable-wrapper"
				height="48"
				@dragstart="onDragStartModule(module.name)"
				@dragend="onDragEndModule">
				<Button
					class="modules-sidebar__module"
					:label="module.displayName"
					:icon="module.icon"
					size="large"
					:severity="module.name === modelValue ? 'primary' : 'secondary'"
					@click="emit('update:modelValue', module.name)" />
			</Draggable>
		</template>
		<form
			v-if="newModule"
			class="modules-sidebar__new-module-container"
			@submit.prevent="saveNewModule">
			<InputText
				ref="newModuleNameInput"
				v-model="newModule.name"
				class="modules-sidebar__new-module-input"
				placeholder="ModuleName (singular)"
				pattern="^[A-Z][a-z]+(?:[A-Z][a-z]+)*$"
				required
				title="Unique module name in PascalCase"
				@input="clearValidationError"
				@blur="newModule?.name ? (newModule.name = anyToPascalCase(newModule.name)) : null"
				@keydown.enter="newModule.name = anyToPascalCase(newModule.name)" />
			<div class="modules-sidebar__new-module-buttons-container">
				<Button
					severity="secondary"
					icon="fal fa-times"
					size="large"
					label="Cancel"
					@click="cancelNewModule" />
				<Button
					severity="success"
					icon="fal fa-floppy-disk"
					size="large"
					type="submit"
					label="Save"
					@submit="saveNewModule" />
			</div>
		</form>
		<Button
			v-if="!newModule"
			class="modules-sidebar__module"
			severity="secondary"
			outlined
			label="New Module"
			icon="fal fa-plus"
			size="large"
			@click="createNewModule" />
	</div>
</template>

<script setup lang="ts">
import { pascalCaseToKebab, pascalCaseToLabel, pascalToSnakeCase } from '@/helpers/strings'
import type { EnrichedModuleSchema } from '@/interfaces/schemas/enrichedModuleSchema'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { nextTick, reactive, ref } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import { anyToPascalCase } from '@/helpers/strings'
import { useDroppable } from '@/helpers/dragAndDrop/droppable'
const draggingModule = ref<EnrichedModuleSchema | null>(null)
const moduleRefs = reactive<Map<string, HTMLElement>>(new Map())
import Draggable from '@/helpers/dragAndDrop/Draggable.vue'

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
	(e: 'update:modules', modules: EnrichedModuleSchema[]): void
	(e: 'newModule', module: EnrichedModuleSchema): void
	(e: 'update-order'): void
}>()

const newModule = ref<EnrichedModuleSchema | null>(null)
const newModuleNameInput = ref<ComponentPublicInstance<typeof InputText> | null>(null)
const orderChanged = ref(false)

const props = defineProps<{
	modules: EnrichedModuleSchema[]
	modelValue?: string
}>()

const { onDragEnd: onDragEndModule, onDragStart: onDragStartModule } = useDroppable({
	dropAreas: moduleRefs,
	onDragStart: (moduleName: string) => {
		draggingModule.value = props.modules.find((m) => m.name === moduleName)!
	},
	onAreaEnter: (moduleName: string) => {
		if (!draggingModule.value) return
		moveModuleTo(draggingModule.value, props.modules.find((m) => m.name === moduleName)!.order)
	},
	onDragEnd: () => {
		draggingModule.value = null
		if (orderChanged.value) {
			emit('update-order')
			orderChanged.value = false
		}
	},
})

function setModuleRef(moduleName: string, component: ComponentPublicInstance | null) {
	// Ignore User module so users can't move it
	if (moduleName === 'User') return
	if (component) {
		if (component.$el instanceof HTMLElement) {
			moduleRefs.set(moduleName, component.$el)
		} else {
			moduleRefs.set(moduleName, component.$el.nextElementSibling)
		}
	} else {
		moduleRefs.delete(moduleName)
	}
}

/**
 * Moves a module to a new position in the schema
 *
 * @param module The module to move
 * @param targetOrder The new position (order) of the column
 */
function moveModuleTo(module: EnrichedModuleSchema, targetOrder: number) {
	orderChanged.value = true

	// The amount of positions we need to move the column
	const moveAmount = Math.abs(module.order - targetOrder)
	if (moveAmount === 0) return
	// The position of the column we start moving from
	const startModuleOrder = module.order
	// The direction we are moving the column
	const direction = module.order < targetOrder ? 'down' : 'up'
	// Copy of columns array that we can loop over (don't want to loop over the
	// original array and change it in the same loop)
	const originalModules = [...props.modules]
	const newModules = [...props.modules]

	// Loop over all the columns we need to move and move them one down or one up
	for (let i = 1; i <= moveAmount; i++) {
		if (direction === 'down') {
			// Move each colmn one up
			newModules[startModuleOrder + i - 1] = originalModules[startModuleOrder + i]
			newModules[startModuleOrder + i - 1].order = startModuleOrder + i - 1
		} else {
			// Move each column one down
			newModules[startModuleOrder - moveAmount + i] =
				originalModules[startModuleOrder - moveAmount + i - 1]
			newModules[startModuleOrder - moveAmount + i].order = startModuleOrder - moveAmount + i
		}
	}

	// Insert the column we moved to the new position
	newModules[targetOrder] = module
	newModules[targetOrder].order = targetOrder

	// Update modules array
	emit('update:modules', newModules)
}

function createNewModule() {
	newModule.value = {
		name: '',
		displayName: '',
		icon: 'far fa-cube',
		model: {
			name: '',
			namePlural: '',
			displayName: '',
			displayNamePlural: '',
			route: '',
			table: '',
			title: '',
			subtitle: '',
			columns: {},
			relations: {},
		},
		order: props.modules.length - 1,
	}
	nextTick(() => newModuleNameInput.value?.$el.focus())
}

function clearValidationError() {
	if (!newModuleNameInput.value) return
	newModuleNameInput.value.$el.setCustomValidity('')
}

function saveNewModule() {
	if (!newModule.value) return
	if (!newModuleNameInput.value) return
	newModule.value.name = anyToPascalCase(newModule.value.name)
	if (props.modules.some((m) => m.name === newModule.value?.name)) {
		newModuleNameInput.value.$el.setCustomValidity('Module name already exists')
		newModuleNameInput.value.$el.reportValidity()
		return
	}
	newModule.value.displayName = pascalCaseToLabel(newModule.value.name)
	newModule.value.model.name = newModule.value.name
	newModule.value.model.namePlural = `${newModule.value.name}s`
	newModule.value.model.displayName = newModule.value.displayName
	newModule.value.model.displayNamePlural = `${newModule.value.displayName}s`
	newModule.value.model.table = pascalToSnakeCase(newModule.value.name + 's')
	newModule.value.model.route = pascalCaseToKebab(newModule.value.name + 's')
	emit('newModule', newModule.value)
	newModule.value = null
}

function cancelNewModule() {
	newModule.value = null
}
</script>

<style lang="scss" scoped>
.modules-sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	width: 240px;

	.modules-sidebar__title {
		font-size: 18px;
		width: 100%;
	}

	.modules-sidebar__new-module-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;

		.modules-sidebar__new-module-input {
			width: 100%;
		}

		.modules-sidebar__new-module-buttons-container {
			display: flex;
			gap: 8px;
			width: 100%;

			& > * {
				flex: 1;
			}
		}
	}

	.modules-sidebar__module {
		width: 240px;
		justify-content: flex-start;
		text-align: left;
	}
}
</style>
