import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'
import type { DeploymentModel, DeploymentStorePayload, DeploymentUpdatePayload } from './Model'
import Api from '@/helpers/models/Api'

export default class DeploymentsApi extends Api<
	DeploymentModel,
	LaravelPaginationResponse<DeploymentModel>,
	DeploymentStorePayload,
	DeploymentUpdatePayload
> {
	route = 'deployments'
}
