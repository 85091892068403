<template>
	<Card
		v-if="delivery && listState.isLoaded"
		class="code-delivery-status"
		border="0">
		<template #content>
			<Avatar
				class="code-delivery-status__avatar"
				:class="{
					'code-delivery-status__avatar--success': delivery?.status === 'success',
					'code-delivery-status__avatar--failed': delivery?.status === 'failed',
				}">
				<i
					v-if="delivery?.status === 'success'"
					class="fa-light fa-check"></i>
				<i
					v-else-if="delivery?.status === 'failed'"
					class="fal fa-exclamation"></i>
				<ProgressSpinner
					v-else
					class="code-delivery-status__avatar-loader"
					indeterminate />
			</Avatar>
			<div class="code-delivery-status__status-container">
				<div class="code-delivery-status__label">Code delivery status</div>
				<div
					v-if="delivery?.status === 'pending'"
					class="code-delivery-status__status">
					Delivering
				</div>
				<div
					v-else-if="delivery?.status === 'success'"
					class="code-delivery-status__status">
					Delivered
				</div>
				<div
					v-else-if="delivery?.status === 'failed'"
					class="code-delivery-status__status">
					{{ delivery?.failure_reason ?? 'Unexpected error' }}
				</div>
				<div
					v-else
					class="code-delivery-status__status">
					Loading
				</div>
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
import { computed, defineProps, onBeforeMount, onUnmounted, watch } from 'vue'
import Avatar from 'primevue/avatar'
import Card from 'primevue/card'
import ProgressSpinner from 'primevue/progressspinner'
import type { Build } from '@/models/Build/Model'
import { useCodeDeliveryListState } from '@/models/CodeDelivery/States'
import type { Purchase } from '@/models/Purchase/Model'
import type { RepositoryConfig } from '@/models/RepositoryConfig/Model'

const props = defineProps<{
	build: Build
	purchases: Purchase[]
	repositoryConfig: RepositoryConfig | undefined
}>()

let statusInterval: any = null
const listState = useCodeDeliveryListState()
// If a new purchase is made, we should refresh the delivery status
let latestPurchaseCount = 0
// If the repository config is updated, we should refresh the delivery status
let latestRepositoryConfigUpdatedAt: null | string = null

listState.defaultParams = {
	per_page: 1,
	fromRelation: {
		model: 'App\\Models\\Build',
		id: props.build.id,
		relation: 'codeDeliveries',
	},
	with: ['creator'],
	orderBy: 'created_at',
	order: 'desc',
}

const delivery = computed(() => {
	return listState?.list.value.at(-1)
})

const intervalShouldBeRunning = computed(
	() =>
		!!delivery.value &&
		delivery.value.status !== 'success' &&
		delivery.value.status !== 'failed' &&
		props.purchases.length > 0 &&
		props.repositoryConfig,
)

// Refresh delivery when purchases change
watch(
	() => props.purchases,
	() => {
		// Only refresh if the purchase count has changed (e.g. a new purchase was made)
		if (latestPurchaseCount !== props.purchases.length) {
			latestPurchaseCount = props.purchases.length
			refresh()
		}
	},
)

// Refresh delivery when repository config changes
watch(
	() => props.repositoryConfig,
	() => {
		// Only refresh if the repository config has been updated
		if (latestRepositoryConfigUpdatedAt !== props.repositoryConfig?.updated_at) {
			latestRepositoryConfigUpdatedAt = props.repositoryConfig?.updated_at ?? null
			refresh()
		}
	},
	{ deep: true },
)

// Refresh delivery when build changes
watch(
	() => props.build,
	(newBuild, oldBuild) => {
		listState.defaultParams.fromRelation!.id = newBuild.id

		// If a build is built or a new build is created we should refresh the delivery status
		if (
			(!newBuild.draft && oldBuild.draft && newBuild.id === oldBuild?.id) ||
			newBuild.id !== oldBuild?.id
		) {
			refresh()
		}
	},
	{ deep: true },
)

onBeforeMount(() => {
	latestPurchaseCount = props.purchases.length
	latestRepositoryConfigUpdatedAt = props.repositoryConfig?.updated_at ?? null
	refresh()
})

onUnmounted(() => {
	clearInterval(statusInterval)
})

function setStatusInterval() {
	// If interval should not be running, clear it
	if (!intervalShouldBeRunning.value) {
		if (statusInterval) clearInterval(statusInterval)
		statusInterval = null
		return
	}
	// If interval is already running, return
	if (statusInterval) {
		return
	}
	// Start interval
	statusInterval = setInterval(async () => {
		// If interval should not be running, clear it
		if (!intervalShouldBeRunning.value) {
			clearInterval(statusInterval)
			statusInterval = null
			return
		}
		await refresh()
	}, 5000)
}

async function refresh() {
	await listState.getList()
	setStatusInterval()
}

defineExpose({
	setStatusInterval,
	refresh,
})
</script>

<style scoped lang="scss">
.code-delivery-status {
	background: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-900);
	}

	&:deep(.p-card-content) {
		display: flex;
		align-items: center;
		gap: 16px;

		.code-delivery-status__avatar {
			color: white;
			height: 32px;
			width: 32px;
			background: transparent;

			&--success {
				background: var(--p-emerald-500);
			}

			&--failed {
				background: var(--p-red-500);
			}
		}

		.code-delivery-status__label {
			font-size: 12px;
			color: var(--p-text-secondary);
		}

		.code-delivery-status__status {
			font-size: 14px;
		}
	}
}
</style>
