import { reactive, ref, unref, type Ref } from 'vue'

interface Area<T> {
	key: T
	el: HTMLElement
	listener: (event: MouseEvent) => void
}

export function useDroppable<T>(options: {
	onAreaEnter?: (key: T) => void
	onDragStart?: (key: T) => void
	onDragEnd?: () => void
	dropAreas: Map<T, HTMLElement>
}) {
	/**
	 * Currently active areas
	 */
	const areas: Area<T>[] = reactive([])
	const isDragging = ref(false)
	const dragging: Ref<T | null> = ref(null)

	/**
	 * Triggers callback when cursor enters area
	 */
	function onMouseEnter(area: T) {
		if (options?.onAreaEnter) {
			options.onAreaEnter(area)
		}
	}

	/**
	 * Sets active drop areas
	 */
	function setDropAreas(dropAreas: Map<T, HTMLElement>) {
		removeDropAreas()

		for (const key of dropAreas.keys()) {
			if (dropAreas.has(key)) {
				const el = dropAreas.get(key)!

				const listener = () => {
					onMouseEnter(key)
				}

				el.addEventListener('mouseenter', listener)

				areas.push({
					key: unref(key),
					el,
					listener,
				})
			}
		}
	}

	/**
	 * Removes all active drop areas
	 */
	function removeDropAreas() {
		for (let i = areas.length - 1; i >= 0; i--) {
			const area = areas[i]
			area.el.removeEventListener('mouseenter', area.listener)
			areas.splice(i, 1)
		}
	}

	/**
	 * Triggers when dragging starts
	 */
	function onDragStart(key: T) {
		isDragging.value = true
		dragging.value = key

		if (options.onDragStart) options.onDragStart(key)

		setDropAreas(options.dropAreas)
	}

	/**
	 * Triggers when dragging ends
	 */
	function onDragEnd() {
		isDragging.value = false
		dragging.value = null

		if (options.onDragEnd) options.onDragEnd()

		removeDropAreas()
	}

	return {
		areas,
		setDropAreas,
		removeDropAreas,
		onDragStart,
		onDragEnd,
	}
}
