import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'
import type { PurchaseModel, PurchaseStorePayload, PurchaseUpdatePayload } from './Model'
import Api from '@/helpers/models/Api'

export default class PurchasesApi extends Api<
	PurchaseModel,
	LaravelPaginationResponse<PurchaseModel>,
	PurchaseStorePayload,
	PurchaseUpdatePayload
> {
	route = 'purchases'
}
