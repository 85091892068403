<template>
	<FormContainer
		class="form"
		:visible
		:as-dialog
		:title="props.isEdit ? 'Edit Repository Config' : 'Create Repository Config'"
		@close="emit('close')">
		<form @submit.prevent="submit">
			<FormInput
				label="Github Repository Url"
				required
				:error-message="formErrors.github_repository_url">
				<InputText
					v-model="formData.github_repository_url"
					:disabled="!!props.forceValues.github_repository_url" />
			</FormInput>
			<FormInput
				label="Github Token"
				required
				:error-message="formErrors.github_token">
				<template #label="slotProps">
					<label :for="slotProps.inputId">Github Token</label>
					<a
						href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#creating-a-fine-grained-personal-access-token"
						target="_blank"
						class="text-xs text-emerald-400">
						Learn how to set up a fine-grained personal access token
					</a>
					<p class="text-xs">At least write access to the selected target repository is required</p>
				</template>
				<template #default>
					<Password
						v-model="formData.github_token"
						fluid
						:disabled="!!props.forceValues.github_token"
						:feedback="false" />
				</template>
			</FormInput>
			<div class="form__footer-container">
				<Button
					v-if="props.isEdit && !props.hideRemove"
					severity="danger"
					icon="fal fa-trash"
					label="Remove"
					outlined
					:loading="loading"
					@click="remove" />
				<Button
					icon="fal fa-save"
					:loading="loading"
					:label="props.isEdit ? 'Update' : 'Create'"
					@click="submit"
					@submit.stop />
			</div>
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import RepositoryConfigsApi from '@/models/RepositoryConfig/Api'
import type { RepositoryConfig } from '@/models/RepositoryConfig/Model'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import { useForm } from '@/helpers/form'
import FormInput from '@/components/FormInput.vue'
import FormContainer from '@/components/FormContainer.vue'

type FormData = {
	github_repository_url: string
	github_token: string
	app_id: number
}

const emit = defineEmits([
	'start-loading',
	'stop-loading',
	'close',
	'created',
	'updated',
	'deleted',
])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: RepositoryConfig['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)

const router = useRouter()

const { formData, loading, formErrors, reset, submit, remove } = useForm({
	api: new RepositoryConfigsApi(),
	defaultData: () => ({
		github_repository_url: '',
		github_token: '',
		app_id: 0,
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'repository-configs-edit', params: { id: entity!.id } })
		}
		emit('created')
	},
	onUpdated: () => emit('updated'),
	onDeleted: () => emit('deleted'),
})

watch(
	() => props.visible,
	(val) => {
		if (val) reset()
	},
)
</script>

<style lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
