import type { BuildModel } from '@/models/Build/Model'
import BuildsApi from '@/models/Build/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class BuildDetailsState extends DetailsState<BuildsApi, BuildModel> {
	api = new BuildsApi()
}

export function useBuildDetailsState() {
	return new BuildDetailsState()
}

export class BuildListState extends ListState<
	BuildsApi,
	BuildModel,
	LaravelPaginationResponse<BuildModel>
> {
	api = new BuildsApi()
}

export function useBuildListState() {
	return new BuildListState()
}
