import type { CodeDeliveryModel } from '@/models/CodeDelivery/Model'
import CodeDeliveriesApi from '@/models/CodeDelivery/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class CodeDeliveryDetailsState extends DetailsState<CodeDeliveriesApi, CodeDeliveryModel> {
	api = new CodeDeliveriesApi()
}

export function useCodeDeliveryDetailsState() {
	return new CodeDeliveryDetailsState()
}

export class CodeDeliveryListState extends ListState<
	CodeDeliveriesApi,
	CodeDeliveryModel,
	LaravelPaginationResponse<CodeDeliveryModel>
> {
	api = new CodeDeliveriesApi()
}

export function useCodeDeliveryListState() {
	return new CodeDeliveryListState()
}
