<template>
	<Card
		v-show="deployment && listState.isLoaded"
		class="deployment-status"
		:color="activeColor"
		border="0">
		<template #content>
			<Avatar
				class="deployment-status__avatar"
				:class="{
					'deployment-status__avatar--success': deployment?.status === 'success',
					'deployment-status__avatar--failure': deployment?.status === 'failure',
				}">
				<i
					v-if="deployment?.status === 'success'"
					class="fa-light fa-check"></i>
				<i
					v-else-if="deployment?.status === 'failure'"
					class="fa-solid fa-exclamation"></i>
				<ProgressSpinner
					v-else
					class="deployment-status__avatar-loader"
					indeterminate />
			</Avatar>
			<div class="deployment-status__status-container">
				<div class="deployment-status__label">Deployment status</div>
				<div
					v-if="deployment?.status === 'building'"
					class="deployment-status__status">
					Building
				</div>
				<div
					v-else-if="deployment?.status === 'pending'"
					class="deployment-status__status">
					Deploying application
					<p class="text-xs text-muted-color italic">This may take about 5 minutes</p>
				</div>
				<div
					v-else-if="deployment?.status === 'deployed'"
					class="deployment-status__status">
					Starting
				</div>
				<div
					v-else-if="deployment?.status === 'success'"
					class="deployment-status__status">
					Deployed
				</div>
				<div
					v-else-if="deployment?.status === 'failure'"
					class="deployment-status__status">
					Unexpected error
				</div>
				<div
					v-else
					class="deployment-status__status">
					Loading
				</div>
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
import { computed, defineProps, onBeforeMount, onUnmounted, watch } from 'vue'
import Avatar from 'primevue/avatar'
import Card from 'primevue/card'
import ProgressSpinner from 'primevue/progressspinner'
import type { Build } from '@/models/Build/Model'
import { useDeploymentListState } from '@/models/Deployment/States'

const emit = defineEmits(['deployed'])

const props = defineProps<{
	build: Build
}>()

let statusInterval: any = null
const listState = useDeploymentListState()

listState.defaultParams = {
	per_page: 1,
	fromRelation: {
		model: 'App\\Models\\Build',
		id: props.build.id,
		relation: 'deployments',
	},
	with: ['creator'],
	orderBy: 'created_at',
	order: 'desc',
}

const deployment = computed(() => {
	return listState?.list.value[0]
})

const activeColor = computed(() => {
	if (deployment.value?.status === 'success') return 'success'
	if (deployment.value?.status === 'failure') return 'error'
	return 'primary'
})

const intervalShouldBeRunning = computed(
	() =>
		!!deployment.value &&
		deployment.value.status !== 'success' &&
		deployment.value.status !== 'failure',
)

const deploymentFinished = computed(() => {
	return deployment.value?.status === 'success' || deployment.value?.status === 'failure'
})

const latestDeploymentLoaded = computed(() => {
	return props.build.id === deployment.value?.build_id
})

watch(
	() => props.build,
	(newBuild, oldBuild) => {
		listState.defaultParams.fromRelation!.id = newBuild.id

		// If a build is built or a new build is created we should refresh the deployment status
		if (!newBuild.draft || newBuild.id !== oldBuild?.id) {
			refresh()
		}
	},
	{ deep: true },
)

onBeforeMount(() => {
	// On page load, refresh the deployment status
	refresh()
})

onUnmounted(() => {
	clearInterval(statusInterval)
})

function setStatusInterval() {
	// If interval should not be running, clear it
	if (!intervalShouldBeRunning.value) {
		if (statusInterval) clearInterval(statusInterval)
		statusInterval = null
		return
	}
	// If interval is already running, return
	if (statusInterval) {
		return
	}
	// Start interval
	statusInterval = setInterval(async () => {
		// If interval should not be running, clear it
		if (!intervalShouldBeRunning.value) {
			clearInterval(statusInterval)
			statusInterval = null
			return
		}
		await refresh()
		if (deploymentFinished.value) {
			emit('deployed')
		}
	}, 5000)
}

async function refresh() {
	// If the deployment for the build is already loaded and finished we don't
	// need to fetch it again
	if (latestDeploymentLoaded.value && deploymentFinished.value) {
		return
	}
	await listState.getList()
	setStatusInterval()
}

defineExpose({
	setStatusInterval,
})
</script>

<style scoped lang="scss">
.deployment-status {
	background: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-900);
	}

	&:deep(.p-card-content) {
		display: flex;
		align-items: center;
		gap: 16px;

		.deployment-status__avatar {
			color: white;
			height: 32px;
			width: 32px;
			background: transparent;

			&--success {
				background: var(--p-emerald-500);
			}

			&--failure {
				background: var(--p-red-500);
			}
		}

		.deployment-status__label {
			font-size: 12px;
			color: var(--p-text-secondary);
		}

		.deployment-status__status {
			font-size: 14px;
		}
	}
}
</style>
