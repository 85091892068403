<template>
	<Draggable
		class="column__draggable-wrapper"
		height="80"
		@dragover="emit('dragover', $event)"
		@dragstart="emit('dragstart')"
		@dragend="emit('dragend')">
		<Card class="column">
			<template #content>
				<div
					class="column__container p-5"
					:class="{ 'column__container--readonly': readonly }"
					@click="readonly ? null : (editVisible = true)">
					<div class="column__header">
						<div class="column__type-container">
							<i :class="'column__icon fal ' + iconMap[schema.type]"></i>
						</div>
						<div class="column__name-container">
							<div class="column__name">{{ schema.displayName }}</div>
							<div class="column__type">{{ camelCaseToLabel(schema.type) }}</div>
						</div>
						<Tag
							v-if="form.title"
							severity="secondary"
							icon="fal fa-star"
							value="Title"></Tag>
						<Tag
							v-if="form.subtitle && false"
							severity="secondary"
							icon="fal fa-stars"
							value="Subtitle"></Tag>
						<Tag
							v-if="schema.searchable"
							severity="secondary"
							icon="fal fa-magnifying-glass"
							value="Searchable"></Tag>
						<Button
							v-if="!readonly"
							text
							rounded
							icon="fal fa-trash"
							severity="secondary"
							@click.stop="emit('delete')" />
					</div>
				</div>
			</template>
		</Card>
	</Draggable>
	<Dialog
		v-model:visible="editVisible"
		header="Edit Column"
		class="column-dialog"
		modal
		@update:visible="emit('cancel')">
		<form
			class="column-dialog__form"
			@submit.prevent="handleSave()">
			<div class="column-dialog__form-section-container">
				<div class="column-dialog__basic-info-container">
					<div class="column-dialog__type-select-container">
						<Select
							:id="`${schema.name}-type`"
							v-model="form.type"
							filter
							class="column-dialog__type-select"
							option-group-label="label"
							option-group-children="items"
							:options="types"
							scroll-height="300px">
							<template #value>
								<div class="column-dialog__type-select-value">
									<i
										class="column-dialog__type-select-value-icon"
										:class="`fal ${iconMap[form.type]}`"></i>
									<div class="column-dialog__type-select-value-name">
										{{ camelCaseToLabel(form.type) }}
									</div>
								</div>
							</template>
							<template #option="slotProps">
								<i :class="'fal ' + iconMap[slotProps.option as keyof typeof iconMap]"></i>
								<div style="margin-left: 10px">
									{{ camelCaseToLabel(slotProps.option) }}
								</div>
							</template>
						</Select>
					</div>
					<div class="column-dialog__input-container column-dialog__name-input-container">
						<label :for="`${schema.name}-name`">Column Name</label>
						<InputText
							:id="`${schema.name}-name`"
							ref="nameInput"
							v-model="form.name"
							class="column-dialog__input"
							title="Unique column name in snake_case"
							pattern="[a-z]+(?:_[a-z]+)*"
							placeholder="column_name (singular)"
							required
							@input="clearValidationErrors()"
							@blur="form.name = anyToSnakeCase(form.name)"
							@keydown.enter="form.name = anyToSnakeCase(form.name)" />
					</div>
				</div>
				<div
					v-if="form.type === 'enum'"
					class="column-dialog__enum-values-container">
					<div class="column-dialog__enum-values-input-controls-container">
						<div class="column-dialog__enum-values-input-container">
							<label :for="`${schema.name}-enum-value`">Enum Values</label>
							<InputText
								:id="`${schema.name}-enum-value`"
								v-model="newEnumValue"
								title="New enum value"
								placeholder="Enter and submit enum value"
								@keydown.enter.prevent.stop="addEnumValue()" />
						</div>
						<Button
							icon="fal fa-plus"
							outlined
							severity="secondary"
							@click="addEnumValue()" />
					</div>
					<div class="column-dialog__enum-values">
						<Chip
							v-for="value in form.enumValues"
							:key="value"
							:label="value"
							removable
							@remove="removeEnumValue(value)" />
					</div>
				</div>
				<div
					v-if="fakerMethods.length > 0"
					class="column-dialog__input-container column-dialog__input-container--span">
					<label :for="`${schema.name}-faker-method`">Fake Data Type</label>
					<Select
						:id="`${schema.name}-faker-method`"
						v-model="form.fakerMethod"
						filter
						class="column-dialog__input"
						:options="fakerMethods"
						option-label="label"
						scroll-height="410px"
						show-clear>
						<template #value="slotProps">
							<div
								v-if="slotProps.value"
								class="column-dialog__faker-method-select-value">
								<div class="column-dialog__faker-method-name">
									{{ slotProps.value.label }}
								</div>
								<div class="column-dialog__faker-method-description">
									{{ slotProps.value.description }}
								</div>
							</div>
							<div
								v-else
								class="column-dialog__faker-method-select-value">
								<div class="column-dialog__faker-method-placeholder">
									Select fake data method to generate data for this column
								</div>
							</div>
						</template>
						<template #option="slotProps">
							<div style="display: flex; flex-direction: column">
								<div style="font-size: 14px; color: var(--p-text-color)">
									{{ slotProps.option.label }}
								</div>
								<div style="font-size: 12px; color: var(--p-text-muted-color)">
									{{ slotProps.option.description }}
								</div>
							</div>
						</template>
					</Select>
				</div>
				<div class="column-dialog__input-container column-dialog__input-container--span">
					<label :for="`${schema.name}-validators`">Validators</label>
					<MultiSelect
						:id="`${schema.name}-validators`"
						v-model="validators"
						:options="availableValidators"
						option-label="columnValidator"
						scroll-height="300px"
						placeholder="Select validators"
						filter>
						<template #value="slotProps">
							<div
								v-if="slotProps.value.length > 0"
								class="column-dialog__validators-select-value">
								<div class="column-dialog__validators-name">
									{{
										slotProps.value
											.map((v: Validator) => snakeCaseToLabel(v.columnValidator))
											.join(', ')
									}}
								</div>
							</div>
							<div
								v-else
								class="column-dialog__validators-select-value">
								<div class="column-dialog__validators-select-placeholder">Select validators</div>
							</div>
						</template>
						<template #option="slotProps">
							<div style="display: flex; flex-direction: column">
								<div style="font-size: 14px; color: var(--p-text-color)">
									{{ snakeCaseToLabel(slotProps.option.columnValidator) }}
								</div>
							</div>
						</template>
					</MultiSelect>
				</div>
				<div class="column-dialog__input-container column-dialog__input-container--span">
					<label>Additional properties</label>
					<div
						v-if="fakerMethods.length > 0"
						class="column-dialog__aditional-property-container">
						<Checkbox
							:binary="true"
							:model-value="isRequired"
							@update:model-value="handleRequiredChanged" />
						<div class="column-dialog__additional-property-label-container">
							<div class="column-dialog__additional-property-label">Required</div>
							<div class="column-dialog__additional-property-description">
								Should this column be required when creating or updating the entity
							</div>
						</div>
					</div>
					<div class="column-dialog__aditional-property-container">
						<Checkbox
							v-model="form.title"
							:binary="true" />
						<div class="column-dialog__additional-property-label-container">
							<div class="column-dialog__additional-property-label">Title</div>
							<div class="column-dialog__additional-property-description">
								This column will be used as the primary identifier in selects, tables and other
								contexts.
							</div>
						</div>
					</div>
					<div
						v-if="false"
						class="column-dialog__aditional-property-container">
						<Checkbox
							v-model="form.subtitle"
							:binary="true" />
						<div class="column-dialog__additional-property-label-container">
							<div class="column-dialog__additional-property-label">Subtitle</div>
							<div class="column-dialog__additional-property-description">
								This column will be used as the secondary identifier for a specific entity of this
								module in selects, tables and other contexts.
							</div>
						</div>
					</div>
					<div class="column-dialog__aditional-property-container">
						<Checkbox
							v-model="form.searchable"
							:binary="true" />
						<div class="column-dialog__additional-property-label-container">
							<div class="column-dialog__additional-property-label">Searchable</div>
							<div class="column-dialog__additional-property-description">
								This column should be used when searching for entities of this module.
							</div>
						</div>
					</div>
				</div>
			</div>
			<Divider />
			<div class="column-dialog__footer">
				<Button
					severity="secondary"
					label="Cancel"
					icon="fa fa-times"
					@click="handleCancel()" />
				<Button
					severity="success"
					label="Save"
					icon="fa fa-floppy-disk"
					type="submit"
					@submit.prevent="handleSave()" />
			</div>
		</form>
	</Dialog>
</template>

<script lang="ts">
type Validator = {
	columnValidator: string
	type: BooleanConstructor
	value: true
}
</script>

<script setup lang="ts">
import type {
	EnrichedColumnSchema,
	EnrichedModuleSchema,
} from '@/interfaces/schemas/enrichedModuleSchema'
import { iconMap } from '@/helpers/typeMap'
import { anyToSnakeCase, camelCaseToLabel, snakeCaseToLabel } from '@/helpers/strings'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag'
import Select from 'primevue/select'
import MultiSelect from 'primevue/multiselect'
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import Divider from 'primevue/divider'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import {
	supportedConstraints,
	typeConstraints,
	type ColumnValidator,
} from '@/interfaces/schemas/typeConstraints'
import { supportedFakerMethods } from '@/interfaces/schemas/fakerMethods'
import Draggable from '@/helpers/dragAndDrop/Draggable.vue'
import type { ComponentPublicInstance } from 'vue'

const types = [
	{
		label: 'Common',
		items: ['string', 'text', 'integer', 'decimal', 'boolean', 'dateTime', 'enum'],
	},
	{
		label: 'Text',
		items: ['char', 'tinyText', 'mediumText', 'longText'],
	},
	{
		label: 'Numeric',
		items: ['double', 'float', 'tinyInteger', 'smallInteger', 'mediumInteger', 'bigInteger'],
	},
	{
		label: 'Date & Time',
		items: ['date', 'time', 'timestamp', 'year'],
	},
	{
		label: 'Misc',
		items: ['binary', 'json', 'foreignId', 'ulid', 'uuid'],
	},
]

const emit = defineEmits<{
	(e: 'save', payload: { column: EnrichedColumnSchema; title: boolean; subtitle: boolean }): void
	(e: 'delete'): void
	(e: 'cancel'): void
	(e: 'dragover', event: DragEvent): void
	(e: 'dragstart'): void
	(e: 'dragend'): void
}>()

const props = defineProps<{
	moduleSchema: EnrichedModuleSchema
	schema: EnrichedColumnSchema
	editing: string
	forceEdit?: boolean
	readonly?: boolean
}>()

const form = ref<{
	type: EnrichedColumnSchema['type']
	name: EnrichedColumnSchema['name']
	fakerMethod: EnrichedColumnSchema['fakerMethod']
	searchable: EnrichedColumnSchema['searchable']
	validators: Validator[]
	title: boolean
	subtitle: boolean
	enumValues: string[]
	order: number
}>(getFreshForm())
const nameInput = ref<ComponentPublicInstance<typeof InputText> | null>(null)
const editVisible = ref(false)
const newEnumValue = ref('')

const validators = computed<ColumnValidator[]>({
	get: () => form.value.validators.map((v) => validatorToColumnValidator(v)).filter((v) => !!v),
	set: (validators: ColumnValidator[]) => {
		form.value.validators = validators.map((v) => columnValidatorToValidator(v))
	},
})

const isRequired = computed(() =>
	form.value.validators.some((v) => v.columnValidator === 'required'),
)

const availableValidators = computed(() =>
	[
		...(supportedConstraints[form.value.type].allowedValidators ?? []),
		...(supportedConstraints[form.value.type].enforcedValidators ?? []),
		...(Object.values(typeConstraints)
			.map((c) => c.columnValidators)
			.flat()
			.filter((v) => v.global === true) ?? []),
	]
		.filter(
			(v) =>
				// Required has a special UI input (chekbox),
				// nullable should be handled by generator for non-required columns
				v.type === Boolean && v.columnValidator !== 'required' && v.columnValidator !== 'nullable',
		)
		.sort((a, b) => a.columnValidator.localeCompare(b.columnValidator)),
)

const fakerMethods = computed(() => {
	return structuredClone(supportedFakerMethods[form.value.type].supported)
})

watch(
	() => props.editing,
	() => {
		if (props.editing === props.schema.name) {
			editVisible.value = true
		}
	},
	{ immediate: true },
)

watch(
	() => props.schema.order,
	() => {
		form.value = getFreshForm()
	},
)

watch(
	() => form.value.type,
	() => {
		form.value.fakerMethod = supportedFakerMethods[form.value.type]?.default
			? structuredClone(supportedFakerMethods[form.value.type].default!)
			: undefined
		form.value.validators = []
		// If there is no faker method available for the selected type the column can't be required
		if (form.value.fakerMethod === undefined) {
			handleRequiredChanged(false)
		}
	},
	{ deep: true },
)
watch(
	() => props.moduleSchema.model.title,
	() => {
		form.value.title = props.moduleSchema.model.title === props.schema.name
	},
)
watch(
	() => props.moduleSchema.model.subtitle,
	() => {
		form.value.title = props.moduleSchema.model.subtitle === props.schema.name
	},
)

onMounted(() => {
	if (props.forceEdit) {
		focusNameInput()
	}
})

function addEnumValue() {
	if (newEnumValue.value && !form.value.enumValues.includes(newEnumValue.value)) {
		form.value.enumValues.push(...newEnumValue.value.split(',').map((v) => v.trim()))
		newEnumValue.value = ''
	} else {
		newEnumValue.value = ''
	}
}

function removeEnumValue(value: string) {
	form.value.enumValues = form.value.enumValues.filter((v) => v !== value)
}

function columnValidatorToValidator(columnValidator: ColumnValidator): Validator {
	return {
		columnValidator: columnValidator.columnValidator,
		type: Boolean,
		value: true,
	}
}

function validatorToColumnValidator(validator: Validator): ColumnValidator {
	return availableValidators.value.find((v) => validator.columnValidator === v.columnValidator)!
}

function getFreshForm() {
	let enumValues: string[] = []
	const enumValuesTypeConstraint = props.schema.typeConstraints?.find(
		(c) => c.name === 'enumValues',
	)
	if (enumValuesTypeConstraint) {
		enumValues = JSON.parse(enumValuesTypeConstraint.params[0] as string)
	}
	return {
		type: props.schema.type,
		name: props.schema.name,
		fakerMethod:
			props.schema.fakerMethod ??
			(supportedFakerMethods[props.schema.type]?.default
				? structuredClone(supportedFakerMethods[props.schema.type]?.default)
				: undefined) ??
			undefined,
		searchable: !!props.schema.searchable,
		validators:
			(props.schema.typeConstraints
				?.filter((v) => v.type === 'validator')
				.map((c) => {
					return {
						columnValidator: c.name,
						type: Boolean,
						value: true,
					}
				})! satisfies Validator[]) ?? [],
		title:
			props.moduleSchema.model.title === props.schema.name ||
			Object.values(props.moduleSchema.model.columns).length === 0,
		subtitle:
			props.moduleSchema.model.subtitle === props.schema.name &&
			props.moduleSchema.model.subtitle !== '',
		enumValues,
		order: props.schema.order,
	}
}

function handleSave() {
	if (!validateInputs()) return
	const column: EnrichedColumnSchema = {
		name: form.value.name,
		displayName: snakeCaseToLabel(form.value.name),
		displayNamePlural: snakeCaseToLabel(form.value.name) + 's',
		type: form.value.type,
		fakerMethod: form.value.fakerMethod,
		searchable: form.value.searchable,
		typeConstraints: form.value.validators.map((v) => ({
			type: 'validator',
			name: v.columnValidator,
			params: typeof v.value === 'boolean' ? [] : [v.value],
		})),
		order: form.value.order,
	}
	if (column.type === 'enum') {
		column.typeConstraints?.push({
			type: 'parameter',
			name: 'enumValues',
			params: [JSON.stringify(form.value.enumValues)],
		})
		if (column.fakerMethod?.method === 'randomElement') {
			column.fakerMethod.params = [JSON.stringify(form.value.enumValues)]
		}
	}
	emit('save', {
		column,
		title: form.value.title,
		subtitle: form.value.subtitle,
	})
	editVisible.value = false
}

function handleCancel() {
	emit('cancel')
	editVisible.value = false
	form.value = getFreshForm()
}

function focusNameInput() {
	nextTick(() => {
		if (nameInput.value) {
			nameInput.value?.$el.focus()
		}
	})
}

function validateInputs() {
	if (
		nameInput.value &&
		form.value.name !== props.schema.name &&
		Object.keys(props.moduleSchema.model.columns).includes(form.value.name)
	) {
		nameInput.value.$el.setCustomValidity('Column with this name already exists')
		nameInput.value.$el.reportValidity()
		return false
	}
	if (
		nameInput.value &&
		form.value.name !== props.schema.name &&
		Object.keys(props.moduleSchema.model.relations).includes(form.value.name)
	) {
		nameInput.value.$el.setCustomValidity('Relation with this name already exists')
		nameInput.value.$el.reportValidity()
		return false
	}
	return true
}

function clearValidationErrors() {
	if (nameInput.value) {
		nameInput.value.$el.setCustomValidity('')
	}
}

function handleRequiredChanged(required: boolean) {
	if (required) {
		form.value.validators.push({
			columnValidator: 'required',
			type: Boolean,
			value: true,
		})
	} else {
		const validatorIndex = form.value.validators.findIndex((v) => v.columnValidator === 'required')
		if (validatorIndex === -1) return
		form.value.validators.splice(validatorIndex, 1)
	}
}
</script>

<style scoped lang="scss">
.column__draggable-wrapper {
	margin-bottom: 12px;
}

.column {
	overflow: hidden;

	&:deep(.p-card-body) {
		padding: 0;

		.p-inplace-content,
		.p-inplace-display {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
		}
	}

	.column__container {
		cursor: pointer;

		@media (prefers-color-scheme: dark) {
			background: var(--p-surface-800);
		}

		&:hover {
			background: var(--p-surface-50);

			@media (prefers-color-scheme: dark) {
				background: var(--p-surface-700);
			}
		}

		&--readonly {
			cursor: default;

			&:hover {
				background: var(--p-surface-0);

				@media (prefers-color-scheme: dark) {
					background: var(--p-surface-800);
				}
			}
		}
	}

	.column__header {
		display: flex;
		align-items: center;
		gap: 16px;
		flex: 1;

		.column__type-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;

			.column__icon {
				font-size: 34px;
				color: var(--p-emerald-500);
			}
		}

		.column__name-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1;

			.column__name {
				font-size: 16px;
				flex: 1;
			}

			.column__type {
				margin-top: -4px;
				font-size: 12px;
				flex: 1;
				text-align: center;
				color: var(--p-primary-500);
			}
		}
	}

	.column__add-validator {
		width: 100%;
	}

	.column__subesctions-container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.column__subsection-title {
			font-size: 18px;
		}
	}
}

.column-dialog {
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 600px;

		.column-dialog__form-section-container {
			padding: 5px;

			.column-dialog__input-container {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 16px;

				&:last-of-type {
					margin-bottom: 0;
				}

				&--row {
					display: grid;
					grid-template-columns: 1fr 1fr 40px;
					gap: 16px;
					width: 100%;
					justify-content: space-between;
				}

				.column-dialog__input {
					width: 100%;
				}

				.column-dialog__save-validator {
					grid-column: 3;
				}
			}

			.column-dialog__basic-info-container {
				display: flex;
				gap: 16px;
				align-items: center;
				margin-bottom: 16px;

				.column-dialog__type-select-container {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.column-dialog__type-select {
						:deep(.p-select-dropdown) {
							display: none;
						}

						.column-dialog__type-select-value {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							width: 70px;
							height: 70px;

							.column-dialog__type-select-value-icon {
								font-size: 28px;
								color: var(--p-primary-500);
							}

							.column-dialog__type-select-value-name {
								width: 100%;
								text-wrap: wrap;
								text-align: center;
								font-size: 12px;
							}
						}
					}
				}

				.column-dialog__name-input-container {
					flex: 1;
				}
			}

			.column-dialog__enum-values-container {
				display: flex;
				flex-direction: column;
				margin-bottom: 10px;

				.column-dialog__enum-values-input-controls-container {
					display: flex;
					gap: 5px;
					align-items: flex-end;

					.column-dialog__enum-values-input-container {
						display: flex;
						flex-direction: column;
						gap: 5px;
						flex: 1;
					}
				}

				.column-dialog__enum-values {
					display: flex;
					flex-wrap: wrap;
					margin-top: 8px;
					gap: 8px;
				}
			}

			.column-dialog__aditional-property-container {
				display: flex;
				gap: 10px;
				align-items: center;
				margin-left: 10px;

				.column-dialog__additional-property-label-container {
					display: flex;
					flex-direction: column;

					.column-dialog__additional-property-label {
						font-size: 14px;
						color: var(--p-text-color);
					}

					.column-dialog__additional-property-description {
						font-size: 12px;
						color: var(--p-text-muted-color);
						margin-top: -4px;
					}
				}
			}

			.column-dialog__faker-method-select-value {
				display: flex;
				flex-direction: column;

				.column-dialog__faker-method-name {
					font-size: 14px;
					color: var(--p-text-color);
				}

				.column-dialog__faker-method-description {
					font-size: 12px;
					color: var(--p-text-muted-color);
				}

				.column-dialog__faker-method-placeholder {
					font-size: 14px;
					color: var(--p-text-muted-color);
				}
			}

			.column-dialog__validators-select-value {
				display: flex;
				flex-direction: column;

				.column-dialog__validators-name {
					font-size: 14px;
					color: var(--p-text-color);
				}

				.column-dialog__validators-select-placeholder {
					font-size: 14px;
					color: var(--p-text-muted-color);
				}
			}
		}
	}

	.column-dialog__footer {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}
}
</style>
