export type FakerMethod = {
	method: string
	label: string
	description: string
	params: any[]
}

export const fakerMethods: Record<string, FakerMethod> = {
	title: {
		method: 'title',
		label: 'Title',
		description: 'Random title (Mr., Ms.)',
		params: [],
	},
	titleMale: {
		method: 'titleMale',
		label: 'Title Male',
		description: 'Male title (Mr.)',
		params: [],
	},
	titleFemale: {
		method: 'titleFemale',
		label: 'Title Female',
		description: 'Female title (Ms.)',
		params: [],
	},
	suffix: {
		method: 'suffix',
		label: 'Suffix',
		description: 'Name suffix (Jr., Sr.)',
		params: [],
	},
	name: {
		method: 'name',
		label: 'Name',
		description: 'Full name (Dr. John Doe Sr.)',
		params: [],
	},
	firstName: {
		method: 'firstName',
		label: 'First Name',
		description: 'First name (Maynard)',
		params: [],
	},
	firstNameMale: {
		method: 'firstNameMale',
		label: 'First Name Male',
		description: 'Male first name (Maynard)',
		params: [],
	},
	firstNameFemale: {
		method: 'firstNameFemale',
		label: 'First Name Female',
		description: 'Female first name (Rachel)',
		params: [],
	},
	lastName: {
		method: 'lastName',
		label: 'Last Name',
		description: 'Last name (Zulauf)',
		params: [],
	},
	cityPrefix: {
		method: 'cityPrefix',
		label: 'City Prefix',
		description: 'City prefix (New)',
		params: [],
	},
	secondaryAddress: {
		method: 'secondaryAddress',
		label: 'Secondary Address',
		description: 'Secondary address (Suite 123)',
		params: [],
	},
	state: {
		method: 'state',
		label: 'State',
		description: 'State (Ohio)',
		params: [],
	},
	stateAbbr: {
		method: 'stateAbbr',
		label: 'State Abbr',
		description: 'State abbreviation (OH)',
		params: [],
	},
	citySuffix: {
		method: 'citySuffix',
		label: 'City Suffix',
		description: 'City suffix (borough)',
		params: [],
	},
	streetSuffix: {
		method: 'streetSuffix',
		label: 'Street Suffix',
		description: 'Street suffix (Keys)',
		params: [],
	},
	buildingNumber: {
		method: 'buildingNumber',
		label: 'Building Number',
		description: 'Building number (484)',
		params: [],
	},
	city: {
		method: 'city',
		label: 'City',
		description: 'City (West Judge)',
		params: [],
	},
	streetName: {
		method: 'streetName',
		label: 'Street Name',
		description: 'Street name (Keegan Trail)',
		params: [],
	},
	streetAddress: {
		method: 'streetAddress',
		label: 'Street Address',
		description: 'Street address (439 Karley Loaf Suite 897)',
		params: [],
	},
	postcode: {
		method: 'postcode',
		label: 'Postcode',
		description: 'Postcode (17916)',
		params: [],
	},
	address: {
		method: 'address',
		label: 'Address',
		description: 'Address (8888 Cummings Vista Apt. 101, Susanbury, NY 95473)',
		params: [],
	},
	country: {
		method: 'country',
		label: 'Country',
		description: 'Country (Falkland Islands (Malvinas))',
		params: [],
	},
	latitude: {
		method: 'latitude',
		label: 'Latitude',
		description: 'Latitude (77.147489)',
		params: [],
	},
	longitude: {
		method: 'longitude',
		label: 'Longitude',
		description: 'Longitude (86.211205)',
		params: [],
	},
	phoneNumber: {
		method: 'phoneNumber',
		label: 'Phone Number',
		description: 'Phone number (827-986-5852)',
		params: [],
	},
	phoneNumberWithExtension: {
		method: 'phoneNumberWithExtension',
		label: 'Phone Number With Extension',
		description: 'Phone number with extension (201-886-0269 x3767)',
		params: [],
	},
	tollFreePhoneNumber: {
		method: 'tollFreePhoneNumber',
		label: 'Toll Free Phone Number',
		description: 'Total free phone number ((888) 937-7238)',
		params: [],
	},
	e164PhoneNumber: {
		method: 'e164PhoneNumber',
		label: 'E164 Phone Number',
		description: 'E164 phone number (+27113456789)',
		params: [],
	},
	catchPhrase: {
		method: 'catchPhrase',
		label: 'Catch Phrase',
		description: 'Catch phrase (Monitored regional contingency)',
		params: [],
	},
	bs: {
		method: 'bs',
		label: 'Bs',
		description: 'Bs (e-enable robust architectures)',
		params: [],
	},
	company: {
		method: 'company',
		label: 'Company',
		description: 'Company (Bogan-Treutel)',
		params: [],
	},
	companySuffix: {
		method: 'companySuffix',
		label: 'Company Suffix',
		description: 'Company suffix (and Sons)',
		params: [],
	},
	jobTitle: {
		method: 'jobTitle',
		label: 'Job Title',
		description: 'Job title (Cashier)',
		params: [],
	},
	realText: {
		method: 'realText',
		label: 'Real Text',
		description: 'Real text (VERY short remarks, and she ran across the...',
		params: [],
	},
	randomDigit: {
		method: 'randomDigit',
		label: 'Random Digit',
		description: 'Random integer from 0 until 9.',
		params: [],
	},
	randomDigitNotNull: {
		method: 'randomDigitNotNull',
		label: 'Random Digit Not Null',
		description: 'Random integer from 1 until 9.',
		params: [],
	},
	randomNumber: {
		method: 'randomNumber',
		label: 'Random Number',
		description: 'Random integer from 0 until 999999999.',
		params: [9],
	},
	randomFloat: {
		method: 'randomFloat',
		label: 'Random Float',
		description: 'Random float.',
		params: [2, 8],
	},
	randomLetter: {
		method: 'randomLetter',
		label: 'Random Letter',
		description: 'Random character from the alphabet',
		params: [],
	},
	// Only for use with enums for now
	randomElements: {
		method: 'randomElements',
		label: 'Random Elements',
		description: 'Random elements from the array',
		params: [],
	},
	randomElement: {
		method: 'randomElement',
		label: 'Random Element',
		description: 'Random element from the array',
		params: [],
	},
	word: {
		method: 'word',
		label: 'Word',
		description: 'Random word',
		params: [],
	},
	words: {
		method: 'words',
		label: 'Words',
		description: '5 Random words',
		params: [5, true],
	},
	sentence: {
		method: 'sentence',
		label: 'Sentence',
		description: 'Random sentence',
		params: [],
	},
	sentences: {
		method: 'sentences',
		label: 'Sentences',
		description: '5 Random sentences',
		params: [5, true],
	},
	paragraph: {
		method: 'paragraph',
		label: 'Paragraph',
		description: 'Random paragraph',
		params: [],
	},
	paragraphs: {
		method: 'paragraphs',
		label: 'Paragraphs',
		description: '5 Random paragraphs',
		params: [5, true],
	},
	text: {
		method: 'text',
		label: 'Text',
		description: 'Random text containing 200 characters',
		params: [],
	},
	unixTime: {
		method: 'unixTime',
		label: 'Unix Time',
		description: 'Random unix time',
		params: [],
	},
	dateTime: {
		method: 'dateTime',
		label: 'Date Time',
		description: 'Random date time',
		params: [],
	},
	dateTimeAD: {
		method: 'dateTimeAD',
		label: 'Date Time AD',
		description: 'Random date between January 1, 0001 and now',
		params: [],
	},
	iso8601: {
		method: 'iso8601',
		label: 'ISO 8601',
		description: 'Random ISO 8601 date time',
		params: [],
	},
	date: {
		method: 'date',
		label: 'Date',
		description: 'Random date',
		params: [],
	},
	time: {
		method: 'time',
		label: 'Time',
		description: 'Random time',
		params: [],
	},
	dateTimeThisCentury: {
		method: 'dateTimeThisCentury',
		label: 'Date Time This Century',
		description: 'Random date time this century',
		params: [],
	},
	dateTimeThisDecade: {
		method: 'dateTimeThisDecade',
		label: 'Date Time This Decade',
		description: 'Random date time this decade',
		params: [],
	},
	dateTimeThisYear: {
		method: 'dateTimeThisYear',
		label: 'Date Time This Year',
		description: 'Random date time this year',
		params: [],
	},
	dateTimeThisMonth: {
		method: 'dateTimeThisMonth',
		label: 'Date Time This Month',
		description: 'Random date time this month',
		params: [],
	},
	amPm: {
		method: 'amPm',
		label: 'AM PM',
		description: 'Random AM or PM',
		params: [],
	},
	dayOfMonth: {
		method: 'dayOfMonth',
		label: 'Day Of Month',
		description: 'Random day of the month',
		params: [],
	},
	dayOfWeek: {
		method: 'dayOfWeek',
		label: 'Day Of Week',
		description: 'Random day of the week',
		params: [],
	},
	month: {
		method: 'month',
		label: 'Month',
		description: 'Random month',
		params: [],
	},
	monthName: {
		method: 'monthName',
		label: 'Month Name',
		description: 'Random month name',
		params: [],
	},
	year: {
		method: 'year',
		label: 'Year',
		description: 'Random year',
		params: [],
	},
	century: {
		method: 'century',
		label: 'Century',
		description: 'Random century',
		params: [],
	},
	timezone: {
		method: 'timezone',
		label: 'Timezone',
		description: 'Random timezone',
		params: [],
	},
	email: {
		method: 'email',
		label: 'Email',
		description: 'Random email',
		params: [],
	},
	safeEmail: {
		method: 'safeEmail',
		label: 'Safe Email',
		description: 'Random safe email',
		params: [],
	},
	freeEmail: {
		method: 'freeEmail',
		label: 'Free Email',
		description: 'Random free email',
		params: [],
	},
	companyEmail: {
		method: 'companyEmail',
		label: 'Company Email',
		description: 'Random company email',
		params: [],
	},
	freeEmailDomain: {
		method: 'freeEmailDomain',
		label: 'Free Email Domain',
		description: 'Random free email domain',
		params: [],
	},
	safeEmailDomain: {
		method: 'safeEmailDomain',
		label: 'Safe Email Domain',
		description: 'Random safe email domain',
		params: [],
	},
	userName: {
		method: 'userName',
		label: 'User Name',
		description: 'Random user name',
		params: [],
	},
	password: {
		method: 'password',
		label: 'Password',
		description: 'Random password',
		params: [],
	},
	domainName: {
		method: 'domainName',
		label: 'Domain Name',
		description: 'Random domain name',
		params: [],
	},
	domainWord: {
		method: 'domainWord',
		label: 'Domain Word',
		description: 'Random domain word',
		params: [],
	},
	tld: {
		method: 'tld',
		label: 'TLD',
		description: 'Random TLD',
		params: [],
	},
	url: {
		method: 'url',
		label: 'URL',
		description: 'Random URL',
		params: [],
	},
	slug: {
		method: 'slug',
		label: 'Slug',
		description: 'Random slug',
		params: [],
	},
	ipv4: {
		method: 'ipv4',
		label: 'IPv4',
		description: 'Random IPv4 address',
		params: [],
	},
	localIpv4: {
		method: 'localIpv4',
		label: 'Local IPv4',
		description: 'Random local IPv4 address',
		params: [],
	},
	ipv6: {
		method: 'ipv6',
		label: 'IPv6',
		description: 'Random IPv6 address',
		params: [],
	},
	macAddress: {
		method: 'macAddress',
		label: 'MAC Address',
		description: 'Random MAC address',
		params: [],
	},
	userAgent: {
		method: 'userAgent',
		label: 'User Agent',
		description: 'Random user agent',
		params: [],
	},
	chrome: {
		method: 'chrome',
		label: 'Chrome',
		description: 'Random Chrome user agent',
		params: [],
	},
	firefox: {
		method: 'firefox',
		label: 'Firefox',
		description: 'Random Firefox user agent',
		params: [],
	},
	safari: {
		method: 'safari',
		label: 'Safari',
		description: 'Random Safari user agent',
		params: [],
	},
	opera: {
		method: 'opera',
		label: 'Opera',
		description: 'Random Opera user agent',
		params: [],
	},
	internetExplorer: {
		method: 'internetExplorer',
		label: 'Internet Explorer',
		description: 'Random Internet Explorer user agent',
		params: [],
	},
	msedge: {
		method: 'msedge',
		label: 'MS Edge',
		description: 'Random MS Edge user agent',
		params: [],
	},
	creditCardType: {
		method: 'creditCardType',
		label: 'Credit Card Type',
		description: 'Random credit card type',
		params: [],
	},
	creditCardNumber: {
		method: 'creditCardNumber',
		label: 'Credit Card Number',
		description: 'Random credit card number',
		params: [],
	},
	creditCardExpirationDate: {
		method: 'creditCardExpirationDate',
		label: 'Credit Card Expiration Date',
		description: 'Random credit card expiration date',
		params: [],
	},
	creditCardExpirationDateString: {
		method: 'creditCardExpirationDateString',
		label: 'Credit Card Expiration Date String',
		description: 'Random credit card expiration date string',
		params: [],
	},
	creditCardDetails: {
		method: 'creditCardDetails',
		label: 'Credit Card Details',
		description: 'Random credit card details',
		params: [],
	},
	iban: {
		method: 'iban',
		label: 'IBAN',
		description: 'Random IBAN',
		params: [],
	},
	swiftBicNumber: {
		method: 'swiftBicNumber',
		label: 'SWIFT/BIC Number',
		description: 'Random SWIFT/BIC number',
		params: [],
	},
	vat: {
		method: 'vat',
		label: 'VAT',
		description: 'Random VAT',
		params: [],
	},
	hexColor: {
		method: 'hexColor',
		label: 'Hex Color',
		description: 'Random hex color',
		params: [],
	},
	safeHexColor: {
		method: 'safeHexColor',
		label: 'Safe Hex Color',
		description: 'Random safe hex color',
		params: [],
	},
	rgbColor: {
		method: 'rgbColor',
		label: 'RGB Color',
		description: 'Random RGB color',
		params: [],
	},
	rgbCssColor: {
		method: 'rgbCssColor',
		label: 'RGB CSS Color',
		description: 'Random RGB CSS color',
		params: [],
	},
	rgbaCssColor: {
		method: 'rgbaCssColor',
		label: 'RGBA CSS Color',
		description: 'Random RGBA CSS color',
		params: [],
	},
	safeColorName: {
		method: 'safeColorName',
		label: 'Safe Color Name',
		description: 'Random safe color name',
		params: [],
	},
	colorName: {
		method: 'colorName',
		label: 'Color Name',
		description: 'Random color name',
		params: [],
	},
	hslColor: {
		method: 'hslColor',
		label: 'HSL Color',
		description: 'Random HSL color',
		params: [],
	},
	mimeType: {
		method: 'mimeType',
		label: 'Mime Type',
		description: 'Random mime type',
		params: [],
	},
	fileExtension: {
		method: 'fileExtension',
		label: 'File Extension',
		description: 'Random file extension',
		params: [],
	},
	uuid: {
		method: 'uuid',
		label: 'UUID',
		description: 'Random UUID',
		params: [],
	},
	ean13: {
		method: 'ean13',
		label: 'EAN13',
		description: 'Random EAN-13 barcode',
		params: [],
	},
	ean8: {
		method: 'ean8',
		label: 'EAN8',
		description: 'Random EAN-8 barcode',
		params: [],
	},
	isbn10: {
		method: 'isbn10',
		label: 'ISBN10',
		description: 'Random ISBN-10',
		params: [],
	},
	isbn13: {
		method: 'isbn13',
		label: 'ISBN13',
		description: 'Random ISBN-13',
		params: [],
	},
	boolean: {
		method: 'boolean',
		label: 'Boolean',
		description: 'Random boolean',
		params: [],
	},
	md5: {
		method: 'md5',
		label: 'MD5',
		description: 'Random MD5 hash',
		params: [],
	},
	sha1: {
		method: 'sha1',
		label: 'SHA1',
		description: 'Random SHA-1 hash',
		params: [],
	},
	sha256: {
		method: 'sha256',
		label: 'SHA256',
		description: 'Random SHA-256 hash',
		params: [],
	},
	locale: {
		method: 'locale',
		label: 'Locale',
		description: 'Random locale',
		params: [],
	},
	countryCode: {
		method: 'countryCode',
		label: 'Country Code',
		description: 'Random country code',
		params: [],
	},
	countryISOAlpha3: {
		method: 'countryISOAlpha3',
		label: 'Country ISO Alpha 3',
		description: 'Random country ISO alpha 3',
		params: [],
	},
	languageCode: {
		method: 'languageCode',
		label: 'Language Code',
		description: 'Random language code',
		params: [],
	},
	currencyCode: {
		method: 'currencyCode',
		label: 'Currency Code',
		description: 'Random currency code',
		params: [],
	},
	emoji: {
		method: 'emoji',
		label: 'Emoji',
		description: 'Random emoji',
		params: [],
	},
	randomHTML: {
		method: 'randomHTML',
		label: 'Random HTML',
		description: 'Random HTML',
		params: [],
	},
	semver: {
		method: 'semver',
		label: 'Semver',
		description: 'Random semver',
		params: [],
	},
}

const allStringsMethods: FakerMethod[] = [
	fakerMethods.title,
	fakerMethods.titleMale,
	fakerMethods.titleFemale,
	fakerMethods.suffix,
	fakerMethods.name,
	fakerMethods.firstName,
	fakerMethods.firstNameMale,
	fakerMethods.firstNameFemale,
	fakerMethods.lastName,
	fakerMethods.cityPrefix,
	fakerMethods.secondaryAddress,
	fakerMethods.state,
	fakerMethods.stateAbbr,
	fakerMethods.citySuffix,
	fakerMethods.streetSuffix,
	fakerMethods.buildingNumber,
	fakerMethods.city,
	fakerMethods.streetName,
	fakerMethods.streetAddress,
	fakerMethods.postcode,
	fakerMethods.address,
	fakerMethods.country,
	fakerMethods.latitude,
	fakerMethods.longitude,
	fakerMethods.phoneNumber,
	fakerMethods.phoneNumberWithExtension,
	fakerMethods.tollFreePhoneNumber,
	fakerMethods.e164PhoneNumber,
	fakerMethods.catchPhrase,
	fakerMethods.bs,
	fakerMethods.company,
	fakerMethods.companySuffix,
	fakerMethods.jobTitle,
	fakerMethods.realText,
	fakerMethods.randomDigit,
	fakerMethods.randomDigitNotNull,
	fakerMethods.randomNumber,
	fakerMethods.randomFloat,
	fakerMethods.randomLetter,
	fakerMethods.word,
	fakerMethods.words,
	fakerMethods.sentence,
	fakerMethods.sentences,
	fakerMethods.paragraph,
	fakerMethods.paragraphs,
	fakerMethods.text,
	fakerMethods.unixTime,
	fakerMethods.dateTime,
	fakerMethods.dateTimeAD,
	fakerMethods.iso8601,
	fakerMethods.date,
	fakerMethods.time,
	fakerMethods.dateTimeThisCentury,
	fakerMethods.dateTimeThisDecade,
	fakerMethods.dateTimeThisYear,
	fakerMethods.dateTimeThisMonth,
	fakerMethods.amPm,
	fakerMethods.dayOfMonth,
	fakerMethods.dayOfWeek,
	fakerMethods.month,
	fakerMethods.monthName,
	fakerMethods.year,
	fakerMethods.century,
	fakerMethods.timezone,
	fakerMethods.email,
	fakerMethods.safeEmail,
	fakerMethods.freeEmail,
	fakerMethods.companyEmail,
	fakerMethods.freeEmailDomain,
	fakerMethods.safeEmailDomain,
	fakerMethods.userName,
	fakerMethods.password,
	fakerMethods.domainName,
	fakerMethods.domainWord,
	fakerMethods.tld,
	fakerMethods.url,
	fakerMethods.slug,
	fakerMethods.ipv4,
	fakerMethods.localIpv4,
	fakerMethods.ipv6,
	fakerMethods.macAddress,
	fakerMethods.userAgent,
	fakerMethods.chrome,
	fakerMethods.firefox,
	fakerMethods.safari,
	fakerMethods.opera,
	fakerMethods.internetExplorer,
	fakerMethods.msedge,
	fakerMethods.creditCardType,
	fakerMethods.creditCardNumber,
	fakerMethods.creditCardExpirationDate,
	fakerMethods.creditCardExpirationDateString,
	fakerMethods.creditCardDetails,
	fakerMethods.iban,
	fakerMethods.swiftBicNumber,
	fakerMethods.vat,
	fakerMethods.hexColor,
	fakerMethods.safeHexColor,
	fakerMethods.rgbColor,
	fakerMethods.rgbCssColor,
	fakerMethods.rgbaCssColor,
	fakerMethods.safeColorName,
	fakerMethods.colorName,
	fakerMethods.hslColor,
	fakerMethods.mimeType,
	fakerMethods.fileExtension,
	fakerMethods.uuid,
	fakerMethods.ean13,
	fakerMethods.ean8,
	fakerMethods.isbn10,
	fakerMethods.isbn13,
	fakerMethods.md5,
	fakerMethods.sha1,
	fakerMethods.sha256,
	fakerMethods.locale,
	fakerMethods.countryCode,
	fakerMethods.countryISOAlpha3,
	fakerMethods.languageCode,
	fakerMethods.currencyCode,
	fakerMethods.emoji,
	fakerMethods.randomHTML,
	fakerMethods.semver,
]

const allNumbersMethods: FakerMethod[] = [
	fakerMethods.randomDigit,
	fakerMethods.randomDigitNotNull,
	fakerMethods.randomNumber,
	fakerMethods.dayOfMonth,
	fakerMethods.dayOfWeek,
	fakerMethods.year,
	fakerMethods.century,
]

const allDateTimeMethods: FakerMethod[] = [
	fakerMethods.unixTime,
	fakerMethods.dateTime,
	fakerMethods.dateTimeAD,
	fakerMethods.iso8601,
	fakerMethods.dateTimeThisCentury,
	fakerMethods.dateTimeThisDecade,
	fakerMethods.dateTimeThisYear,
	fakerMethods.dateTimeThisMonth,
]

export const supportedFakerMethods: Record<
	string,
	{
		default: FakerMethod | null
		supported: FakerMethod[]
	}
> = {
	bigInteger: {
		default: fakerMethods.randomNumber,
		supported: [...allNumbersMethods],
	},
	binary: {
		default: fakerMethods.randomHTML,
		supported: [fakerMethods.randomHTML],
	},
	boolean: {
		default: fakerMethods.boolean,
		supported: [fakerMethods.boolean],
	},
	char: {
		default: fakerMethods.randomLetter,
		supported: [
			fakerMethods.randomLetter,
			fakerMethods.randomDigit,
			fakerMethods.randomDigitNotNull,
		],
	},
	dateTimeTz: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	dateTime: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	date: {
		default: fakerMethods.date,
		supported: [fakerMethods.date],
	},
	decimal: {
		default: fakerMethods.randomFloat,
		supported: [...allNumbersMethods, fakerMethods.randomFloat],
	},
	double: {
		default: fakerMethods.randomFloat,
		supported: [...allNumbersMethods, fakerMethods.randomFloat],
	},
	enum: {
		default: fakerMethods.randomElement,
		supported: [fakerMethods.randomElement],
	},
	float: {
		default: fakerMethods.randomFloat,
		supported: [...allNumbersMethods, fakerMethods.randomFloat],
	},
	foreignId: {
		default: fakerMethods.randomNumber,
		supported: [fakerMethods.randomDigitNotNull, fakerMethods.randomNumber],
	},
	// foreignIdFor: {},
	// foreignUlid: {},
	// foreignUuid: {},
	// id: {},
	integer: {
		default: fakerMethods.randomNumber,
		supported: [...allNumbersMethods],
	},
	ipAddress: {
		default: fakerMethods.ipv4,
		supported: [fakerMethods.ipv4, fakerMethods.localIpv4, fakerMethods.ipv6],
	},
	json: {
		default: null,
		supported: [],
	},
	jsonb: {
		default: null,
		supported: [],
	},
	longText: {
		default: fakerMethods.paragraph,
		supported: [...allStringsMethods],
	},
	macAddress: {
		default: fakerMethods.macAddress,
		supported: [fakerMethods.macAddress],
	},
	mediumInteger: {
		default: fakerMethods.randomNumber,
		supported: [...allNumbersMethods],
	},
	mediumText: {
		default: fakerMethods.paragraph,
		supported: [...allStringsMethods],
	},
	// morphs: {
	// 	default: null,
	// 	supported: [],
	// },
	rememberToken: {
		default: fakerMethods.sha256,
		supported: [fakerMethods.md5, fakerMethods.sha1, fakerMethods.sha256, fakerMethods.uuid],
	},
	set: {
		default: fakerMethods.randomElements,
		supported: [fakerMethods.randomElements],
	},
	smallInteger: {
		default: fakerMethods.randomNumber,
		supported: [...allNumbersMethods],
	},
	softDeletesTz: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	softDeletes: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	string: {
		default: fakerMethods.sentence,
		supported: [...allStringsMethods],
	},
	text: {
		default: fakerMethods.paragraph,
		supported: [...allStringsMethods],
	},
	timeTz: {
		default: fakerMethods.time,
		supported: [fakerMethods.time],
	},
	time: {
		default: fakerMethods.time,
		supported: [fakerMethods.time],
	},
	timestampTz: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	timestamp: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	timestampsTz: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	timestamps: {
		default: fakerMethods.dateTime,
		supported: [...allDateTimeMethods],
	},
	tinyInteger: {
		default: fakerMethods.randomNumber,
		supported: [...allNumbersMethods],
	},
	tinyText: {
		default: fakerMethods.sentence,
		supported: [...allStringsMethods],
	},
	// uuidMorphs: {
	// 	default: null,
	// 	supported: [],
	// },
	// ulidMorphs: {
	// 	default: null,
	// 	supported: [],
	// },
	ulid: {
		default: null,
		supported: [],
	},
	uuid: {
		default: fakerMethods.uuid,
		supported: [fakerMethods.uuid],
	},
	year: {
		default: fakerMethods.year,
		supported: [fakerMethods.year],
	},
}
