<template>
	<Header :title="headerTitle" />
	<Container class="complete-checkout">
		<Card
			v-if="complete"
			class="complete-checkout__card">
			<template #title>Thank you for you purchase!</template>
			<template #content>
				<p>
					We appreciate your business! A confirmation email will be sent to
					<strong id="customer-email">{{ userEmail }}</strong
					>. If you have any questions, please email
					<a href="mailto:orders@codedjen.com">support@codedjen.com</a>. We are happy to answer any
					questions and will reach out as soon as possible.
				</p>
				<p>
					If you haven't done it yet, please configure your application's github repository
					information, so that we may deliver the source code to you as soon as possible.
				</p>
				<div class="ga-2 d-flex flex-row pa-0 w-100 justify-space-between">
					<Button
						as="router-link"
						:to="`/apps/${route.params.id as string}`"
						icon="fal fa-mobile-alt"
						outlined
						label="Back to application" />
					<Button
						as="router-link"
						:to="`/apps/${route.params.id as string}/repo-config`"
						icon="fab fa-github"
						label="Repository configuration" />
				</div>
			</template>
		</Card>
	</Container>
</template>

<script setup lang="ts">
import Container from '@/components/Container.vue'
import Header from './components/Header.vue'
import StripeApi from '@/helpers/api/StripeApi'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Card from 'primevue/card'
import Button from 'primevue/button'
import { useAppDetailsState } from '@/models/App/States'

const route = useRoute()
const router = useRouter()
const stripeApi = new StripeApi()
const complete = ref(false)
const userEmail = ref('')
const detailsState = useAppDetailsState()

const headerTitle = computed(() =>
	detailsState.details.value?.name ? detailsState.details.value?.name : 'Loading Details',
)

onBeforeMount(async () => {
	await getDetails()
})

onMounted(() => initialize())

async function initialize() {
	if (!route.query.session_id) {
		alert('Payment error, no session id provided. Please try again.')
		return router.push('/')
	}
	const res = await stripeApi.completeCheckout({ session_id: route.query.session_id as string })

	if (res.data.status === 'open') {
		return router.replace('/apps/' + route.params.id + '/checkout')
	} else if (res.data.status === 'complete') {
		complete.value = true
		userEmail.value = res.data.customer_email
	}
}

async function getDetails() {
	await detailsState.getDetails(route.params.id as string)
}
</script>

<style scoped lang="scss">
.complete-checkout {
	display: flex;
	align-items: center;
	justify-content: center;

	.complete-checkout__card {
		width: 600px;

		&:deep(.p-card-content) {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
}
</style>
