import type { PurchaseModel } from '@/models/Purchase/Model'
import PurchasesApi from '@/models/Purchase/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class PurchaseDetailsState extends DetailsState<PurchasesApi, PurchaseModel> {
	api = new PurchasesApi()
}

export function usePurchaseDetailsState() {
	return new PurchaseDetailsState()
}

export class PurchaseListState extends ListState<
	PurchasesApi,
	PurchaseModel,
	LaravelPaginationResponse<PurchaseModel>
> {
	api = new PurchasesApi()
}

export function usePurchaseListState() {
	return new PurchaseListState()
}
