<template>
	<Header :details-state />
	<ProgressBar
		v-if="detailsState.isLoading.value || updating"
		mode="indeterminate"
		class="details__progress-bar"
		style=""></ProgressBar>
	<Container class="details-container">
		<div
			v-if="detailsState.isLoaded.value"
			class="details">
			<div class="details__schema-container">
				<div class="details__schema-header">
					<Inplace class="details__app-name">
						<template #display>
							<h2 class="details__schema-header-title">
								{{ detailsState.details.value?.name ?? 'Loading' }} Specification
							</h2>
						</template>
						<template #content="{ closeCallback }">
							<form
								class="details__app-name-input-container"
								@submit.prevent="updateAppName(closeCallback)">
								<InputText
									ref="moduleNameInput"
									v-model="appName"
									placeholder="Application Name"
									required
									title="Application name" />
								<Button
									icon="far fa-floppy-disk"
									severity="success"
									type="submit"
									:loading="updating"
									@submit.prevent="updateAppName(closeCallback)" />
							</form>
						</template>
					</Inplace>
					<Select
						v-model="activeBuildId"
						:options="versionSelectOptions"
						option-label="label"
						option-value="id"
						placeholder="Select Version"
						label="App Version" />
					<!--<Button
                        icon="fal fa-plus"
                        label="New Version"
                        severity="secondary"
                        @click="router.push({ name: 'apps-new-version' })" />-->
				</div>
				<div class="details__schema">
					<ModulesSidebar
						v-model="selectedModule"
						v-model:modules="modules"
						@update-order="updateBuild"
						@new-module="addNewModule" />
					<div class="details__columns-relations-container">
						<Message
							v-if="moduleSchema?.name === 'User'"
							class="details__user-info-message"
							severity="info"
							icon="fa-light fa-circle-info"
							size="small"
							>The User module is pre-defined and cannot be removed. Create relations to and from
							other modules to connect entities to the app's users.</Message
						>
						<div
							v-if="selectedModule"
							class="details__columns-relations">
							<div class="details__module-details">
								<Inplace :style="isModuleReadonly(moduleSchema) ? 'pointer-events: none;' : ''">
									<template #display>
										<i :class="'details__module-icon fa-light ' + moduleSchema?.icon"></i>
									</template>
									<template #content="{ closeCallback }">
										<span class="details__inplace-input-container">
											<IconSelect v-model="moduleSchema.icon" />
											<Button
												icon="far fa-floppy-disk"
												severity="success"
												:loading="updating"
												@click="handleIconChange(closeCallback)" />
										</span>
									</template>
								</Inplace>
								<Inplace
									class="details__module-name-inplace"
									:style="isModuleReadonly(moduleSchema) ? 'pointer-events: none;' : ''">
									<template #display>
										<h3 class="details__module-name">{{ moduleSchema?.displayName }}</h3>
									</template>
									<template #content="{ closeCallback }">
										<form
											class="details__inplace-input-container"
											@submit.prevent="handleNameChange(closeCallback)">
											<InputText
												ref="moduleNameInput"
												v-model="moduleName"
												placeholder="ModuleName (singular)"
												pattern="^[A-Z][a-z]+(?:[A-Z][a-z]+)*$"
												title="Unique module name in PascalCase"
												@input="clearValidationError"
												@blur="moduleName = anyToPascalCase(moduleName)" />
											<Button
												icon="far fa-floppy-disk"
												type="submit"
												severity="success"
												:loading="updating"
												@submit.prevent="handleNameChange(closeCallback)" />
										</form>
									</template>
								</Inplace>
								<Button
									v-if="!isModuleReadonly(moduleSchema)"
									outlined
									label="Delete Module"
									icon="fal fa-trash"
									severity="danger"
									@click="deleteModule()" />
							</div>
							<Divider />
							<div class="details__section-header">
								<div class="details__section-title">Columns</div>
								<Button
									v-if="!isModuleReadonly(moduleSchema)"
									outlined
									label="New Column"
									icon="fal fa-plus"
									severity="secondary"
									@click="addNewColumn" />
							</div>
							<Column
								v-for="column in sortedColumns"
								:key="column.name"
								:ref="
									(component) =>
										setColumnRef(column.name, component as ComponentPublicInstance | null)
								"
								:editing="editingColumn"
								:schema="column"
								:module-schema="moduleSchema"
								:readonly="isModuleReadonly(moduleSchema)"
								@save="handleColumnSave(column.name, $event)"
								@delete="handleColumnDelete(column.name)"
								@dragstart="onDragStartColumn(column.name)"
								@dragend="onDragEndColumn" />
							<Column
								v-if="newColumn"
								:editing="editingColumn"
								:schema="newColumn"
								:module-schema="moduleSchema"
								force-edit
								@cancel="(newColumn = null), (editingColumn = '')"
								@delete="(newColumn = null), (editingColumn = '')"
								@save="handleSaveNewColumn" />
							<div class="spacer" />
							<div class="details__section-header">
								<div class="details__section-title">Relations</div>
								<Button
									outlined
									label="New Relation"
									icon="fal fa-plus"
									severity="secondary"
									@click="newRelation" />
							</div>
							<Relation
								v-for="relation in moduleSchema!.model.relations"
								:key="relation.name"
								class="details__relation"
								:schema="relation"
								:inverse-module-schema="moduleRegistry.get(relation.model)!"
								:module-schema="moduleSchema"
								:readonly="false"
								@delete="handleDeleteRelation(relation)" />
						</div>
					</div>
				</div>
			</div>
			<div class="details__misc-info-container">
				<DeploymentStatus
					v-if="activeBuild"
					:build="activeBuild"
					@deployed="getDetails()" />
				<CodeDeliveryStatus
					v-if="activeBuild"
					:build="activeBuild"
					:purchases="detailsState.details.value?.purchases ?? []"
					:repository-config="detailsState.details.value?.repository_config" />
				<Button
					v-if="activeBuild && activeBuild.draft"
					severity="secondary"
					icon="fal fa-gears"
					:loading="building"
					label="Build Version"
					:disabled="modules.length === 0"
					@click="buildVersion" />
				<Button
					v-if="authStore.user?.id === 1 && activeBuild && !activeBuild.draft"
					severity="secondary"
					icon="fal fa-gears"
					:loading="building"
					label="Rebuild Version"
					:disabled="modules.length === 0"
					@click="rebuildVersion" />
				<Button
					v-if="
						activeDeployment && activeDeployment.status === 'success' && activeDeployment.active
					"
					as="a"
					target="blank"
					icon="fal fa-browser"
					severity="secondary"
					label="View App"
					:href="`http://user-${activeDeployment?.creator?.id}.codedjen.io`"
					:disabled="!deploymentSucceeded" />
				<Button
					icon="fab fa-github"
					severity="secondary"
					label="Repository Config"
					@click="router.push({ name: 'apps-repo-config' })" />
				<Button
					v-if="authStore.user?.id === 1 && activeDeployment && !activeDeployment.active"
					severity="secondary"
					icon="fal fa-rocket"
					:loading="activeDeployment?.status === 'building'"
					label="Redeploy"
					outlined
					@click="redeploy" />
				<Button
					v-if="authStore.user?.id === 1 && activeBuild?.draft === false && isDev"
					severity="secondary"
					icon="fal fa-terminal"
					:loading="activeDeployment?.status === 'building'"
					label="Copy start command"
					outlined
					@click="copyStartCommand" />
				<Button
					v-if="authStore.user?.id === 1 && activeDeployment"
					as="a"
					:href="`https://github.com/CodeDjenAi/${detailsState.details.value?.creator?.id}-${detailsState.details.value?.id}`"
					icon="fal fa-code-branch"
					severity="secondary"
					target="blank"
					label="View Repository"
					outlined
					:loading="activeDeployment?.status === 'building'" />
				<Button
					v-if="!purchaseExists"
					as="router-link"
					:to="{ name: 'apps-checkout' }"
					icon="fal fa-cart-shopping"
					severity="info"
					label="Purchase"
					:loading="activeDeployment?.status === 'building'" />
				<Button
					v-else-if="licenceToSmall"
					as="router-link"
					:to="{ name: 'apps-checkout' }"
					icon="fal fa-cart-plus"
					severity="info"
					label="Upgrade licence"
					:loading="activeDeployment?.status === 'building'"></Button>
				<ConfettiButton
					v-else
					as="a"
					class="confettiButton"
					button-text="App already purchased!">
				</ConfettiButton>
			</div>
		</div>
	</Container>
	<Dialog
		:visible="route.name === 'apps-new-version'"
		width="800"
		@update:visible="
			$event ? $router.push({ name: 'apps-new-version' }) : $router.push({ name: 'apps-details' })
		">
		<Form
			:id="route.params.id as string"
			:is-edit="true"
			:build-id="activeBuild?.id"
			@built="handleEditFormFinish()" />
	</Dialog>
	<RepositoryConfigForm
		v-if="detailsState.details.value?.id"
		:id="detailsState.details.value?.repository_config?.id"
		:visible="route.name === 'apps-repo-config'"
		:is-edit="!!detailsState.details.value?.repository_config?.id"
		as-dialog
		:hide-inputs="['app_id']"
		:force-values="{ app_id: Number(route.params.id) }"
		:should-redirect="false"
		@close="router.push({ name: 'apps-details' })"
		@created="handleRepositoryConfigUpdate()"
		@updated="handleRepositoryConfigUpdate()" />
	<NewRelationForm
		v-if="moduleSchema"
		v-model:visible="newRelationFormOpen"
		:module-schema="moduleSchema"
		:all-module-schemas="modules"
		@create="handleSaveRelation" />
</template>

<script setup lang="ts">
import Form from './components/Form.vue'
import RepositoryConfigForm from '../RepositoryConfig/components/Form.vue'
import Header from './components/Header.vue'
import DeploymentStatus from './components/DeploymentStatus.vue'
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, reactive, ref, watch } from 'vue'
import { computed } from 'vue'
import type {
	EnrichedColumnSchema,
	EnrichedModuleSchema,
	EnrichedRelationSchema,
} from '@/interfaces/schemas/enrichedModuleSchema'
import { useModuleRegistry } from '@/helpers/moduleRegistry'
import { useAuthStore } from '@/stores/Auth'
import CodeDeliveryStatus from './components/CodeDeliveryStatus.vue'
import ProgressBar from 'primevue/progressbar'
import Button from 'primevue/button'
import Select from 'primevue/select'
import Container from '@/components/Container.vue'
import Dialog from 'primevue/dialog'
import ModulesSidebar from './components/ModulesSidebar.vue'
import Column from './components/Column.vue'
import Relation from './components/Relation.vue'
import Inplace from 'primevue/inplace'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'
import IconSelect from '@/components/IconSelect.vue'
import { fakerMethods } from '@/interfaces/schemas/fakerMethods'
import NewRelationForm from './components/NewRelationForm.vue'
import {
	anyToPascalCase,
	pascalCaseToKebab,
	pascalCaseToLabel,
	pascalToSnakeCase,
} from '@/helpers/strings'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useAppDetailsState } from '@/models/App/States'
import type { Build } from '@/models/Build/Model'
import BuildsApi from '@/models/Build/Api'
import DeploymentsApi from '@/models/Deployment/Api'
import type { ComponentPublicInstance } from 'vue'
import { useDroppable } from '@/helpers/dragAndDrop/droppable'
import ConfettiButton from '@/views/App/ConfettiButton.vue'
import type { AppSize } from '@/models/App/Model'
import Message from 'primevue/message'

const isDev = import.meta.env.DEV
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const confirm = useConfirm()
const toast = useToast()
const detailsState = useAppDetailsState()
const selectedModule = ref<string>('')
const updating = ref<boolean>(false)
const modules = ref<EnrichedModuleSchema[]>(reactive([]))
const newRelationFormOpen = ref<boolean>(false)
const building = ref<boolean>(false)
const moduleName = ref<string>('')
const moduleNameInput = ref<ComponentPublicInstance<typeof InputText> | null>(null)
const newColumn = ref<EnrichedColumnSchema | null>(null)
const editingColumn = ref('')
const appName = ref<string>('')
const columnRefs = reactive<Map<string, HTMLElement>>(new Map())
const draggingColumn = ref<EnrichedColumnSchema | null>(null)
const { onDragEnd: onDragEndColumn, onDragStart: onDragStartColumn } = useDroppable({
	dropAreas: columnRefs,
	onDragStart: (column: string) => {
		draggingColumn.value = columns.value[column]
	},
	onAreaEnter: (column) => {
		if (!draggingColumn.value) return
		moveColumnTo(draggingColumn.value, columns.value[column].order)
	},
	onDragEnd: () => {
		updateModule()
		draggingColumn.value = null
	},
})

const selectedModuleSchemaIndex = computed(
	() => modules.value.findIndex((module) => module.name === selectedModule.value)!,
)
const activeBuildId = ref<Build['id'] | undefined>()
const activeBuild = computed(() =>
	detailsState.details.value?.builds?.find((build) => build.id === activeBuildId.value),
)
const activeDeployment = computed(() => activeBuild.value?.deployments?.at(-1))
const deploymentSucceeded = computed(
	() => activeDeployment.value?.status === 'success' && activeDeployment.value?.active,
)
const moduleRegistry = computed(() => useModuleRegistry(modules.value))
const columns = computed(() => moduleSchema.value.model.columns)
const sortedColumns = computed(() =>
	Object.values(moduleSchema.value.model.columns).sort((a, b) => a.order - b.order),
)

const appSize: Record<AppSize, number> = {
	small: 5,
	medium: 10,
	large: 15,
} as const

const boughtAppSize = computed<number>(() => {
	const licence = detailsState.details.value?.licence
	if (!licence) return 0
	else return appSize[licence]
})
const currentAppsize = computed<number>(() => {
	return modules.value.length - 1 // ignore pre-generated user module
})

const licenceToSmall = computed<boolean>(() => {
	return currentAppsize.value > boughtAppSize.value
})

/**
 * Currently selected module schema
 */
const moduleSchema = computed(() => modules.value[selectedModuleSchemaIndex.value])

watch(
	() => detailsState.details.value,
	() => {
		appName.value = detailsState.details.value?.name ?? ''
	},
)
watch(activeBuildId, () => {
	modules.value = activeBuild.value
		? (JSON.parse(activeBuild.value!.app_schema) as EnrichedModuleSchema[])
		: []
	if (!modules.value.some((m) => m.name === selectedModule.value)) {
		selectedModule.value = modules.value[0]?.name
	}
})
watch(
	selectedModule,
	() => {
		if (!selectedModule.value) return
		moduleName.value = selectedModule.value
	},
	{ immediate: true },
)

const purchaseExists = computed(() => !!detailsState.details.value?.purchases?.length)
const versionSelectOptions = computed(() => {
	const output =
		detailsState.details.value?.builds?.map((build: Build, index: number) => ({
			...build,
			label: `Version ${index + 1}${build.draft ? ' (Draft)' : ''}`,
		})) ?? []
	output.reverse()
	return output
})

onBeforeMount(async () => {
	await getDetails()
	activeBuildId.value = detailsState.details.value?.builds?.at(-1)?.id
})

/**
 * Moves a column to a new position in the schema
 *
 * @param column The column to move
 * @param targetOrder The new position (order) of the column
 */
function moveColumnTo(column: EnrichedColumnSchema, targetOrder: number) {
	// The position of the column we start moving from
	const startColumnOrder = column.order
	// The amount of positions we need to move the column
	const moveAmount = Math.abs(column.order - targetOrder)
	// The direction we are moving the column
	const direction = column.order < targetOrder ? 'down' : 'up'
	// Copy of columns array that we can loop over (don't want to loop over the
	// original array and change it in the same loop)
	const originalColumns = [...sortedColumns.value]
	// Loop over all the columns we need to move and move them one down or one up
	for (let i = 1; i <= moveAmount; i++) {
		if (direction === 'down') {
			const currentColumn = originalColumns[startColumnOrder + i]
			columns.value[currentColumn.name].order -= 1
		} else {
			const currentColumn = originalColumns[startColumnOrder - i]
			columns.value[currentColumn.name].order += 1
		}
	}
	// Move the provided column to the new position
	columns.value[column.name].order = targetOrder
}

function setColumnRef(columnName: string, component: ComponentPublicInstance | null) {
	if (component) {
		if (component.$el instanceof HTMLElement) {
			columnRefs.set(columnName, component.$el)
		} else {
			columnRefs.set(columnName, component.$el.nextElementSibling)
		}
	} else {
		columnRefs.delete(columnName)
	}
}

function isModuleReadonly(moduleSchema: EnrichedModuleSchema) {
	return moduleSchema.name === 'User'
}

function updateAppName(closeCallback: () => void) {
	detailsState.update({ name: appName.value })
	closeCallback()
}

async function getDetails() {
	await detailsState.getDetails(route.params.id as string, {
		with: ['creator', 'builds.deployments.creator', 'repositoryConfig', 'purchases'],
	})
}

async function handleEditFormFinish() {
	router.push({ name: 'apps-details' })
	await getDetails()
	activeBuildId.value = detailsState.details.value?.builds?.at(-1)?.id
}

async function redeploy() {
	if (!activeBuild.value?.id) return
	await new DeploymentsApi().store({
		build_id: activeBuild.value?.id,
	})
	getDetails()
}

async function handleRepositoryConfigUpdate() {
	await getDetails()
}

/**
 * Adds a new module to the schema
 *
 * @param module The module to add
 */
async function addNewModule(module: EnrichedModuleSchema) {
	// Move User module to the end of the list
	modules.value[modules.value.length - 1].order += 1
	modules.value.push(modules.value[modules.value.length - 1])

	// Add new module to the list
	modules.value[modules.value.length - 2] = module
	selectedModule.value = module.name

	updateBuild()
}

async function createNewDraftBuild() {
	const res = await new BuildsApi().store({
		app_id: Number(route.params.id as string),
		draft: true,
		parent_build_id: Number(activeBuild.value!.id),
		app_schema: JSON.stringify(modules.value),
	})
	await getDetails()
	activeBuildId.value = res.data.id
}

function clearValidationError() {
	if (!moduleNameInput.value) return
	moduleNameInput.value.$el.setCustomValidity('')
}

async function updateModule() {
	if (
		moduleName.value !== selectedModule.value &&
		moduleNameInput.value &&
		modules.value.some((m) => m.name === moduleName.value)
	) {
		moduleNameInput.value.$el.setCustomValidity('Module name already exists')
		moduleNameInput.value.$el.reportValidity()
		return
	}
	moduleSchema.value.name = moduleName.value
	selectedModule.value = moduleName.value
	moduleSchema.value.displayName = pascalCaseToLabel(moduleName.value)
	moduleSchema.value.model.name = moduleName.value
	moduleSchema.value.model.namePlural = moduleName.value + 's'
	moduleSchema.value.model.displayName = pascalCaseToLabel(moduleName.value)
	moduleSchema.value.model.displayNamePlural = pascalCaseToLabel(moduleName.value) + 's'
	moduleSchema.value.model.table = pascalToSnakeCase(moduleName.value + 's')
	moduleSchema.value.model.route = pascalCaseToKebab(moduleName.value + 's')
	await updateBuild()
}

function deleteModule() {
	confirm.require({
		message: `Are you sure you want to delete the ${selectedModule.value} module?`,
		header: 'Danger Zone',
		icon: 'fa fa-exclamation-triangle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true,
		},
		acceptProps: {
			label: 'Delete',
			severity: 'danger',
		},
		accept: async () => {
			const deletedModuleIndex = selectedModuleSchemaIndex.value
			for (const relation of Object.values(moduleSchema.value.model.relations)) {
				const inverseModuleIndex = modules.value.findIndex(
					(module) => module.name === relation.model,
				)
				delete modules.value[inverseModuleIndex].model.relations[relation.inverse]
			}
			modules.value.splice(deletedModuleIndex, 1)
			for (let i = deletedModuleIndex; i < modules.value.length; i++) {
				modules.value[i].order -= 1
			}
			selectedModule.value = modules.value[0]?.name
			updateBuild()
		},
		reject: () => {},
	})
}

async function updateBuild() {
	updating.value = true
	if (!activeBuild.value?.draft) {
		await createNewDraftBuild()
	}
	await new BuildsApi().update(activeBuildId.value as number, {
		app_schema: JSON.stringify(modules.value),
	})
	updating.value = false
}

/**
 * Sets the module title to given column, or unsets the given columns as the
 * title (if the given column is the current title) and sets the title to the
 * first module column
 *
 * @param name The name of the column to set as title
 * @param setTitle Whether to set or unset the title
 */
async function setTitle(name: string, setTitle: boolean) {
	// If module title points to a column that doesn't exist, unset title
	if (!columns.value[moduleSchema.value.model.title]) {
		moduleSchema.value.model.title = ''
	}
	// If no columns exist, unset title
	if (sortedColumns.value.length === 0) {
		moduleSchema.value.model.title = ''
	}

	// If given column doesn't exist abort title change
	if (!columns.value[name]) {
		return
	}

	// If title should be unset, unset title if it's currently set to given column
	if (!setTitle && moduleSchema.value.model.title === name) {
		moduleSchema.value.model.title = ''
	}

	// If title should be set, set title to given column
	if (setTitle) {
		moduleSchema.value.model.title = name
	}

	// If title is not set, set it to the first column
	if (moduleSchema.value.model.title === '') {
		moduleSchema.value.model.title = sortedColumns.value[0].name
	}
}

function setSubtitle(name: string, value: boolean) {
	if (!moduleSchema.value.model.columns[moduleSchema.value.model.subtitle ?? '']) {
		moduleSchema.value.model.subtitle = null
	}
	if (!value && moduleSchema.value.model.subtitle === name) {
		moduleSchema.value.model.subtitle = null
	} else if (value) {
		moduleSchema.value.model.subtitle = name
	}
}

async function handleColumnSave(
	originalColumnName: string,
	{ column, title, subtitle }: { column: EnrichedColumnSchema; title: boolean; subtitle: boolean },
) {
	delete columns.value[originalColumnName]
	columns.value[column.name] = column
	setTitle(column.name, title)
	setSubtitle(column.name, subtitle)
	updateModule()
}

function addNewColumn() {
	newColumn.value = {
		name: '',
		displayName: '',
		displayNamePlural: '',
		type: 'string',
		fakerMethod: fakerMethods.sentence,
		searchable: false,
		order: sortedColumns.value.length,
	}
	editingColumn.value = ''
}

async function handleSaveNewColumn({
	column,
	title,
	subtitle,
}: {
	column: EnrichedColumnSchema
	title: boolean
	subtitle: boolean
}) {
	if (!newColumn.value) return
	moduleSchema.value.model.columns[column.name] = column
	setTitle(column.name, title)
	setSubtitle(column.name, subtitle)
	newColumn.value = null
	editingColumn.value = ''
	updateBuild()
}

function handleColumnDelete(name: string) {
	const deletedOrder = columns.value[name].order
	delete moduleSchema.value.model.columns[name]
	Object.values(moduleSchema.value.model.columns).forEach((column) => {
		if (column.order > deletedOrder) {
			column.order -= 1
		}
	})
	setTitle(name, false)
	setSubtitle(name, false)
	updateBuild()
}

function newRelation() {
	newRelationFormOpen.value = true
}

async function handleSaveRelation({
	relation,
	inverseRelation,
}: {
	relation: EnrichedRelationSchema
	inverseRelation: EnrichedRelationSchema | null
}) {
	moduleSchema.value.model.relations[relation.name] = relation
	const inverseModuleIndex = modules.value.findIndex((module) => module.name === relation.model)
	if (inverseRelation) {
		modules.value[inverseModuleIndex].model.relations[inverseRelation.name] = inverseRelation
	}
	newRelationFormOpen.value = false
	updateBuild()
}

async function handleDeleteRelation(relation: EnrichedRelationSchema) {
	delete moduleSchema.value.model.relations[relation.name]
	const inverseModuleIndex = modules.value.findIndex((module) => module.name === relation.model)
	delete modules.value[inverseModuleIndex].model.relations[relation.inverse]
	updateBuild()
}

function isSchemaValid() {
	if (modules.value.length === 1) {
		toast.add({
			severity: 'error',
			summary: 'Invalid schema',
			detail: 'App must have at least one non-User module',
			life: 5000,
		})
		return false
	}
	for (const module of modules.value) {
		if (Object.keys(module.model.columns).length === 0) {
			toast.add({
				severity: 'error',
				summary: 'Invalid schema',
				detail: `Module ${module.name} has no columns. Each module must have at least one column`,
				life: 5000,
			})
			return false
		}
	}
	return true
}

async function buildVersion() {
	if (!isSchemaValid()) return
	if (!activeBuildId.value) return
	building.value = true
	try {
		const res = await new BuildsApi().update(String(activeBuildId.value), { draft: false })
		activeBuildId.value = res.data.id
		await getDetails()
	} finally {
		building.value = false
	}
}

async function rebuildVersion() {
	await updateModule()
	await buildVersion()
}

async function handleIconChange(closeCallback: () => void) {
	updateModule()
	closeCallback()
}

async function handleNameChange(closeCallback: () => void) {
	updateModule()
	closeCallback()
}

function copyStartCommand() {
	navigator.clipboard.writeText(
		`make start-app APP=${detailsState.details.value?.id} BUILD=${activeBuildId.value}`,
	)
	toast.add({
		severity: 'info',
		summary: 'Command copied',
		detail:
			'Added `' +
			`make start-app APP=${detailsState.details.value?.id} BUILD=${activeBuildId.value}` +
			'` to clipboard',
		life: 3000,
	})
}
</script>

<style scoped lang="scss">
.details__progress-bar {
	height: 4px;
	margin-bottom: -4px;
	width: 100%;
	border-radius: 0;
}

.spacer {
	height: 10px;
}

.details-container {
	display: flex;
	justify-content: center;

	.details {
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: 1400px;

		.details__schema-container {
			flex: 1;

			.details__schema-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				.details__app-name {
					:deep(.p-inplace-display) {
						padding: 6px;
					}

					.details__schema-header-title {
						flex: 1;
						font-size: 24px;
					}

					.details__app-name-input-container {
						display: flex;
						gap: 5px;
						align-items: center;
						height: 50px;
					}
				}
			}

			.details__schema {
				display: flex;
				gap: 20px;
				padding: 10px 0;

				.details__section-header {
					display: flex;
					align-items: space-between;
					width: 100%;
					margin-bottom: 10px;

					.details__section-title {
						display: flex;
						align-items: center;
						font-size: 22px;
						flex: 1;
					}
				}

				.details__user-info-message {
					margin-top: 8px;
					margin-bottom: 6px;

					:deep(.p-message-content) {
						gap: 10px;
					}
				}

				.details__columns-relations-container {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 5px;

					.details__columns-relations {
						flex: 1;
						border-radius: 10px;
						padding: 20px;
						box-shadow: var(--p-card-shadow);
						background-color: var(--p-surface-0);

						@media (prefers-color-scheme: dark) {
							background-color: var(--p-surface-900);
						}

						.details__module-name-inplace {
							flex: 1;

							.details__module-name {
								font-size: 26px;
								margin: 0;
							}
						}

						.details__inplace-input-container {
							display: flex;
							gap: 5px;
						}

						.details__module-details {
							display: flex;
							flex: 1;
							align-items: center;
							gap: 5px;

							:deep(.p-inplace) {
								padding: 0;

								.p-inplace-display {
									padding: 6px;
								}
							}

							.details__module-icon {
								font-size: 34px;
								color: var(--p-primary-500);
							}
						}
					}
				}
			}
		}

		.details__misc-info-container {
			display: flex;
			flex-direction: column;
			min-width: 300px;
			gap: 10px;
			padding: 0 20px;

			.p-button {
				width: 100%;
			}
		}
	}
}
</style>
