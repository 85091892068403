<template>
	<span
		ref="draggedPlaceholder"
		class="draggable-wrapper"
		:style="{ height: `${height}px` }">
		<slot />
	</span>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useDraggable } from '@/helpers/dragAndDrop/draggable'

const emit = defineEmits<{
	(e: 'dragover', event: DragEvent): void
	(e: 'dragstart'): void
	(e: 'dragend'): void
}>()

const { height } = defineProps<{
	height: number | string
}>()

const draggedPlaceholder = ref<HTMLElement | null>(null)
const draggedElement = computed(
	() => draggedPlaceholder.value?.firstElementChild as HTMLElement | null,
)

onMounted(() => {
	if (!draggedPlaceholder.value || !draggedElement.value) return

	useDraggable(draggedElement.value, { onDragStart, onDragStop })
})

/**
 * Inform parent that dragging has started.
 * Add global style (see CSS below).
 */
function onDragStart() {
	if (!draggedElement.value) return
	emit('dragstart')
	draggedElement.value.classList.add('draggable--dragging')
	document.body.classList.add('dragging')
}

/**
 * Inform parent that dragging has stopped.
 * Remove global class.
 */
function onDragStop() {
	if (!draggedElement.value) return
	emit('dragend')
	draggedElement.value.classList.remove('draggable--dragging')
	document.body.classList.remove('dragging')
}
</script>

<style scoped>
.draggable-wrapper {
	display: block;
	width: 100%;
}
.draggable-wrapper {
	pointer-events: initial;
}
.draggable--dragging {
	pointer-events: none;
}
</style>

<style>
body.dragging {
	&,
	* {
		&,
		&:hover,
		&:active {
			cursor: grabbing !important;
		}
	}

	* {
		pointer-events: none;
	}
}
</style>
