import { onBeforeUnmount, ref } from 'vue'

export function useDraggable(
	el: HTMLElement,
	options?: {
		onDragStart?: (event: MouseEvent) => void
		onDragStop?: (event: MouseEvent) => void
		zIndex?: string
	},
) {
	/**
	 * Is element being dragged
	 */
	const isDragging = ref(false)

	/**
	 * Inial position of the element and cursor
	 */
	const pos = {
		x: 0,
		y: 0,
		width: 0,
		height: 0,
		cursorX: 0,
		cursorY: 0,
	}

	/**
	 * Sets styles on element to simulate dragging
	 */
	function setStyles({ top, left }: { top: number; left: number }) {
		el.style.position = 'fixed'
		el.style.width = `${pos.width}px`
		el.style.height = `${pos.height}px`
		el.style.top = `${top}px`
		el.style.left = `${left}px`
		el.style.zIndex = options?.zIndex || '100'
	}

	/**
	 * Resets element's styles
	 */
	function resetStyles() {
		el.style.position = ''
		el.style.width = ''
		el.style.height = ''
		el.style.top = ''
		el.style.left = ''
		el.style.zIndex = ''
	}

	/**
	 * Prevents click from triggering when element is dropped
	 */
	let suppressClick = false
	function onClick(event: MouseEvent) {
		if (suppressClick) {
			event.preventDefault()
			event.stopImmediatePropagation()
		}
	}

	/**
	 * Triggers when actual dragging starts
	 */
	function startDragging(event: MouseEvent) {
		isDragging.value = true
		suppressClick = true

		if (options?.onDragStart) {
			options.onDragStart(event)
		}
	}

	/**
	 * Checks movement of cursor after element has been grabbed.
	 * If cursor has moved enough, it triggers dragging.
	 * Once dragging has started, it repositions element with every cursor movement.
	 */
	function onMouseMove(event: MouseEvent) {
		const movedX = event.clientX - pos.cursorX
		const movedY = event.clientY - pos.cursorY

		if (!isDragging.value) {
			/**
			 * How many pixels does element have to be dragged before triggering drag?
			 * This is used to prevent accidentaly triggering drag when clicking.
			 */
			const threshold = 3
			const isPastThreshold = Math.max(movedX, movedX * -1, movedY, movedY * -1) > threshold
			if (!isPastThreshold) return

			// Initiate dragging
			startDragging(event)
		}

		event.preventDefault()

		setStyles({
			left: pos.x + movedX,
			top: pos.y + movedY,
		})
	}

	/**
	 * Stops dragging when element is released
	 */
	function onMouseUp(event: MouseEvent) {
		if (options?.onDragStop) {
			options.onDragStop(event)
		}

		event.preventDefault()

		pos.x = 0
		pos.y = 0
		pos.width = 0
		pos.height = 0
		pos.cursorX = 0
		pos.cursorY = 0
		resetStyles()

		isDragging.value = false

		// Supress all click events for 100ms after release
		setTimeout(() => {
			suppressClick = false
		}, 100)

		window.removeEventListener('mousemove', onMouseMove)
		window.removeEventListener('mouseup', onMouseUp)
	}

	/**
	 * Sets initial position of the element and cursor when element is grabbed.
	 * Sets listeners for cursor movement and dropping.
	 */
	function onMouseDown(event: MouseEvent) {
		event.preventDefault()

		const { x, y, width, height } = el.getBoundingClientRect()

		pos.x = x
		pos.y = y
		pos.width = width
		pos.height = height
		pos.cursorX = event.clientX
		pos.cursorY = event.clientY

		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)
	}

	/**
	 * Prevents default behavior of native dragstart event
	 */
	function onNativeDragStart(event: DragEvent) {
		event.preventDefault()
	}

	/**
	 * Set initial listener that will check if element is grabbed.
	 */
	el.addEventListener('mousedown', onMouseDown)

	/**
	 * If the element or its content is selected, our dragging implementation
	 * is overriden by native dragging of selected content. This prevents that from happening.
	 */
	el.addEventListener('dragstart', onNativeDragStart)

	/**
	 * If element has click event listeners they can accidentaly trigger when element
	 * is dropped. This prevents that from happening.
	 */
	el.addEventListener('click', onClick, true)

	/**
	 * Remove persistant listeners.
	 */
	onBeforeUnmount(() => {
		el.removeEventListener('mousedown', onMouseDown)
		el.removeEventListener('dragstart', onNativeDragStart)
		el.removeEventListener('click', onClick, true)
	})

	return {
		isDragging,
	}
}
