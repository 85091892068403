<template>
	<Header
		:list-state="listState"
		search
		title="Apps"
		@create="appFormVisible = true" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="name"
				header="Name" />
			<Column
				:style="{ maxWidth: '72px', width: '72px' }"
				header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
	<Form
		as-dialog
		should-redirect
		:visible="appFormVisible"
		@close="appFormVisible = false" />
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { ref, onBeforeMount } from 'vue'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import { useRouter } from 'vue-router'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'
import Container from '@/components/Container.vue'
import Form from './components/Form.vue'
import { useAppListState } from '@/models/App/States'
import type { App } from '@/models/App/Model'

const listState = useAppListState()
const router = useRouter()

onBeforeMount(() => {
	listState.getList()
})

const appFormVisible = ref(false)

function openDetails(item: { data: App }) {
	router.push({ name: 'apps-details', params: { id: item.data.id } })
}
</script>
