<template>
	<Header
		:list-state="listState"
		search
		title="Builds" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="query"
				header="Query" />
			<Column
				field="app_schema"
				header="App Schema" />
			<Column
				field="head_sha"
				header="Head Sha" />
			<Column header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Container from '@/components/Container.vue'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'
import { useBuildListState } from '@/models/Build/States'
import type { Build } from '@/models/Build/Model'

const listState = useBuildListState()
const router = useRouter()

onBeforeMount(() => {
	listState.getList()
})

function openDetails(item: { data: Build }) {
	router.push({ name: 'builds-edit', params: { id: item.data.id } })
}
</script>
