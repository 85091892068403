import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'
import type {
	RepositoryConfigModel,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload,
} from './Model'
import Api from '@/helpers/models/Api'

export default class RepositoryConfigsApi extends Api<
	RepositoryConfigModel,
	LaravelPaginationResponse<RepositoryConfigModel>,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload
> {
	route = 'repository-configs'
}
