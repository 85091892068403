<template>
	<FormContainer
		class="form"
		:visible
		:as-dialog
		title="Create App"
		@close="emit('close')">
		<form @submit.prevent="submit">
			<SelectButton
				v-model="toggleAIValue"
				class="form__ai-option-button"
				:options="toggleAIOptions" />
			<FormInput
				label="Name"
				required
				:error-message="formErrors.name">
				<InputText
					v-model="formData.name"
					fluid
					:disabled="!!props.forceValues.name"
					placeholder="App name" />
			</FormInput>
			<FormInput
				v-if="toggleAIValue === ToggleOptions.AIAssisted"
				label="AI Prompt"
				required
				:error-message="formErrors.ai_prompt">
				<template #label="slotProps">
					<label :for="slotProps.inputId">AI Prompt</label>
					<p class="text-xs italic text-muted-color">
						Describe an application, and AI will generate it for you. You can always edit it later.
					</p>
				</template>
				<template #default>
					<Textarea
						v-model="formData.ai_prompt"
						placeholder="Create a management application for a small wellness center."
						:rows="8"
						class="p-2 w-full" />
				</template>
			</FormInput>
			<p
				v-if="loading"
				class="text-xs text-muted-color italic">
				This may take a few minutes
			</p>
			<Button
				:loading="loading"
				icon="fal fa-save"
				:label="isEdit ? 'Update' : 'Create'"
				type="submit"
				@submit.prevent="submit" />
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import type { App } from '@/models/App/Model'
import AppsApi from '@/models/App/Api'
import FormContainer from '@/components/FormContainer.vue'
import FormInput from '@/components/FormInput.vue'
import SelectButton from 'primevue/selectbutton'
import { useForm } from '@/helpers/form'

type FormData = {
	name: string
	ai_prompt: string
}

enum ToggleOptions {
	Manual = 'Manual',
	AIAssisted = 'AI Assisted',
}
const emit = defineEmits(['start-loading', 'stop-loading', 'close', 'created', 'updated'])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: App['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)
const toggleAIValue = ref(ToggleOptions.Manual)
const toggleAIOptions = ref([ToggleOptions.Manual, ToggleOptions.AIAssisted])

const router = useRouter()

const { formData, loading, formErrors, reset, submit } = useForm({
	api: new AppsApi(),
	defaultData: () => ({
		name: '',
		ai_prompt: '',
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'apps-details', params: { id: entity!.id } })
		}
		emit('created')
	},
	onUpdated: () => emit('updated'),
})

watch(
	() => props.visible,
	(val) => {
		if (val) reset()
	},
)
</script>

<style scoped lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
		.form__ai-option-button {
			width: 100%;
			& :deep(.p-togglebutton) {
				width: 50%;
			}
		}
	}
}
</style>
