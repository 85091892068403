<template>
	<div
		class="navigation"
		:class="{ 'navigation--expanded': locked }"
		@mouseover="isSidebarOpen = true"
		@mouseleave="isSidebarOpen = false">
		<div class="navigation__header">
			<Avatar
				:label="initials"
				class="navigation__avatar" />
			<span
				v-tooltip.bottom="auth.user!.email"
				class="navigation__email"
				>{{ auth.user!.email }}</span
			>
			<Button
				severity="secondary"
				:icon="lockIcon"
				text
				@click="toggleLocked" />
		</div>
		<div class="navigation__links-container">
			<router-link
				class="navigation__link-item"
				to="/apps">
				<i class="navigation__link-icon fat fa-mobile-alt"></i>
				<div class="navigation__link-title">Apps</div>
			</router-link>
			<template v-if="auth.user?.id === 1">
				<router-link
					class="navigation__link-item"
					to="/builds">
					<i class="navigation__link-icon fat fa-hammer"></i>
					<div class="navigation__link-title">Build</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/code-deliveries">
					<i class="navigation__link-icon fat fa-shipping-fast"></i>
					<div class="navigation__link-title">Code Deliveries</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/deployments">
					<i class="navigation__link-icon fat fa-rocket"></i>
					<div class="navigation__link-title">Deployments</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/purchases">
					<i class="navigation__link-icon fat fa-shopping-cart"></i>
					<div class="navigation__link-title">Purchases</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/repository-configs">
					<i class="navigation__link-icon fab fa-github"></i>
					<div class="navigation__link-title">Repository Configs</div>
				</router-link>
			</template>
			<router-link
				class="navigation__link-item"
				to="/logout">
				<i class="navigation__link-icon fat fa-arrow-right-from-bracket"></i>
				<div class="navigation__link-title">Logout</div>
			</router-link>
		</div>
		<div class="navigation__logo-container">
			<img
				src="@/assets/logo.svg"
				alt="logo" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/Auth'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'

const auth = useAuthStore()
const locked = ref(localStorage.getItem('sidebarLocked') === 'true')
const isSidebarOpen = ref(false)

const initials = computed(
	() => auth.user!.email[0].toUpperCase() + auth.user!.email[1].toUpperCase(),
)
const lockIcon = computed(() => {
	if (locked.value) {
		return 'fal fa-lock'
	} else {
		return 'fal fa-lock-open'
	}
})

function toggleLocked() {
	locked.value = !locked.value
	localStorage.setItem('sidebarLocked', locked.value.toString())
}
</script>

<style scoped lang="scss">
.navigation {
	border-right: 1px solid var(--p-surface-200);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 52px;
	transition: width 0.3s;
	background-color: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		border-right-color: var(--p-surface-900);
		background-color: var(--p-surface-950);
	}

	&.navigation--expanded,
	&:hover {
		width: 255px;

		.navigation__logo-container {
			padding: 30px;
		}
	}

	.navigation__header {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-bottom: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;
		text-overflow: ellipsis;

		@media (prefers-color-scheme: dark) {
			border-bottom-color: var(--p-surface-950);
		}

		.navigation__email {
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.navigation__avatar {
			min-width: 30px;
		}
	}

	.navigation__links-container {
		width: 255px;
		flex: 1;

		.navigation__link-item {
			display: flex;
			align-items: center;
			padding: 10px;
			gap: 10px;
			color: var(--p-text-color);
			text-decoration: none;

			&:hover {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			&.router-link-active {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			.navigation__link-title {
				flex: 1;
			}

			.navigation__link-icon {
				font-size: 22px;
				color: var(--p-surface-500);
				width: 32px;
				display: flex;
				justify-content: center;

				@media (prefers-color-scheme: dark) {
					color: var(--p-surface-400);
				}
			}
		}
	}

	.navigation__logo-container {
		padding: 8px;
	}
}
</style>
