<template>
	<Select
		class="icon-select"
		:options
		option-value="icon"
		option-label="label"
		filter
		:filter-fields="['search']"
		:loading
		:model-value="props.modelValue"
		@update:model-value="emit('update:modelValue', $event)"
		@filter="search($event.value)">
		<template #value="slotProps">
			<div
				v-if="slotProps.value"
				class="icon-select__value">
				<i :class="`${slotProps.value}`"></i>
				<div style="margin-left: 10px">
					{{ kebabCaseToLabel(slotProps.value.split(' ')[1].slice(3)) }}
				</div>
			</div>
			<span v-else>
				{{ slotProps.placeholder }}
			</span>
		</template>
		<template #option="slotProps">
			<i :class="`${slotProps.option.prefix} ${slotProps.option.id}`"></i>
			<div style="margin-left: 10px">{{ slotProps.option.label }}</div>
		</template>
	</Select>
</template>

<script lang="ts"></script>

<script setup lang="ts">
import FontAwesomeApi, { type Icon } from '@/helpers/integrations/fontAwesomeApi'
import Select from 'primevue/select'
import { onBeforeMount, ref } from 'vue'
import { kebabCaseToLabel } from '@/helpers/strings'

const emit = defineEmits(['update:modelValue'])
const fontAwesomeApi = new FontAwesomeApi()

const options = ref<Icon[]>([])
const loading = ref(false)
const props = defineProps({
	modelValue: {
		type: String,
		required: true,
	},
})

onBeforeMount(() => {
	search(props.modelValue !== '' ? props.modelValue.split(' ')[1].replace('fa-', '') : '')
})

async function search(searchString: string) {
	options.value = options.value.map((option) => ({ ...option, search: searchString }))
	loading.value = true
	const query = searchString
	const res = await fontAwesomeApi.search(query)
	// If request is canceled request resolves with undefined
	if (!res) {
		return
	}
	options.value = res.data.data.search.map((icon) => ({
		...icon,
		search: query,
		id: `fa-${icon.id}`,
		icon: `${Object.values(icon.familyStylesByLicense?.pro).some((i) => i.prefix === 'far') ? 'far' : Object.values(icon.familyStylesByLicense?.pro)[0].prefix} fa-${icon.id}`,
		prefix: Object.values(icon.familyStylesByLicense?.pro).some((i) => i.prefix === 'far')
			? 'far'
			: Object.values(icon.familyStylesByLicense?.pro)[0].prefix,
	}))
	loading.value = false
}
</script>

<style scoped lang="scss">
.icon-select {
	.icon-select__value {
		display: flex;
		align-items: center;
	}
}
</style>
